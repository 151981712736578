import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const MegaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: gray;
  width: 100%;
  height: 100%;
`;

const MegaImage = styled.img`
  margin-top: 50px;
  max-width: 100%;
  height: auto;
`;

export default function Mega() {
  return (
    <MegaWrapper>
      <MegaImage src="/images/mega_img.gif" alt="Mega" />
      <Outlet />
    </MegaWrapper>
  );
}
