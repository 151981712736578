import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";

const PopupWarpper = styled.div`
  display: ${({ $visible }) => $visible ? "block" : "none"};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const PopupInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 90%; // 최대 너비 제한
  max-height: 90vh; // 최대 높이 제한
  overflow: auto; // 내용이 많을 경우 스크롤
`;

const CloseButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #FFC107;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
`;

const DontShowLink = styled.span`
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
  font-size: 14px;
`;

const GlobalPopup = ({ visible, setVisible, imageUrl, imgWidth, imgHeight, mImgWidth, mImgHeight }) => {
  console.log('팝업 props:', { visible, imageUrl, imgWidth, imgHeight, mImgWidth, mImgHeight });
  const isMobile = window.innerWidth <= 423;
  const currentWidth = isMobile ? mImgWidth : imgWidth;
  const currentHeight = isMobile ? mImgHeight : imgHeight;

  useEffect(() => {
    const dontShowPopup = Cookies.get("dontShowPopup");
    if (dontShowPopup) {
      setVisible(false);
    }
  }, [setVisible]);

  const handleDontShow = () => {
    Cookies.set("dontShowPopup", 'true', { expires: 1 });
    setVisible(false);
  };
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);
 return (
    <PopupWarpper $visible={visible}>
      <PopupInner
        style={{
          transform: `translate(calc(-50% + ${position.x}px), calc(-50% + ${position.y}px))`,
          cursor: isDragging ? 'grabbing' : 'grab'
        }}
        onMouseDown={handleMouseDown}
      >
        <img 
          src={imageUrl} 
          alt="popup"
          style={{ 
            width: currentWidth + 'px',
            height: currentHeight + 'px',
            maxWidth: "100%",
            maxHeight: "100%",
            userSelect: 'none'  // 드래그 중 이미지 선택 방지
          }} 
          draggable={false}  // 이미지 기본 드래그 방지
        />
        <FooterWrapper>
          <DontShowLink onClick={handleDontShow}>오늘 하루 열지않기</DontShowLink>
          <CloseButton onClick={() => setVisible(false)}>닫기</CloseButton>
        </FooterWrapper>
      </PopupInner>
    </PopupWarpper>
  );
}

export default GlobalPopup;
