import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation,useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { couponCodeAtom, couponPriceAtom } from '../../atoms/atomSample';
import Cookies from 'js-cookie';

const Container = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 2rem auto;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProductCard = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  display: ${props => props.hidden ? 'none' : 'flex'};
  align-items: center;
  gap: 20px;
`;

const ProductImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
`;

const ProductDetails = styled.div`
  flex: 1;
`;

const ProductName = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const ProductPrice = styled.p`
  font-size: 1rem;
  color: #555;
`;

const ProductQuantity = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  color: #555;
`;

const QuantityButton = styled.button`
  padding: 5px 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const QuantityInput = styled.input`
  width: 50px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;

const TotalPrice = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: #000;
`;

const PurchaseButton = styled.button`
  background-color: #FF5733;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-right:10px;

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const ApplyAndBookButton = styled.button`
  background-color: #FFC107;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 20px;
  display: block;
  width: 100%;

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

// 여기서 추가한 부분
const FormContainer = styled.div`
  margin-top: 2rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%; /* Ensures the FormGroup takes full width */
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%; /* Ensures the input takes full width */
  box-sizing: border-box; /* Ensures padding and border are included in width */
`;

const SubmitButton = styled.button`
  background-color: #FFC107;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 20px;
  width: 100%; /* Ensures the button takes full width */
  box-sizing: border-box; /* Ensures padding and border are included in width */

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

// 첫 번째 화면: 상품 선택 화면
export default function ProductRecommendation() {
  const [products, setProducts] = useState([]);
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponProductId, setCouponProductId] = useState(null);

  const [couponCode, setCouponCode] = useRecoilState(couponCodeAtom);
  const [couponPrice, setCouponPrice] = useRecoilState(couponPriceAtom);
  const [couponPrices, setCouponPrices] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
    fetchCouponPrice();
  }, []);
  const handleClose = () => {
    // 부모 창에 팝업 닫기 신호 전송
    if (window.opener) {
      window.opener.postMessage('applyPopup', window.location.origin);
    }
    window.close();
  };
  const fetchProducts = async () => {
    try {
      const response = await fetch('/product/getProductRecommendations', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('상품 가져오기 실패');
      }

      const data = await response.json();
      if (data.length === 0) {
        handleApplyAndBook();
        return;
      }
      setProducts(data);
      const initialQuantities = data.reduce((acc, product) => {
        acc[product.id] = 1;
        return acc;
      }, {});
      setSelectedQuantities(initialQuantities);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchCouponPrice = async () => {
    const couponCodeFromUrl = new URLSearchParams(window.location.search).get('couponCode');
    setCouponCode(couponCodeFromUrl);

    if (!couponCodeFromUrl) {
      console.error('쿠폰 코드가 URL에 없습니다.');
      return;
    }

    try {
      const response = await fetch(`/request/getCouponPrice?couponCode=${couponCodeFromUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('쿠폰 가격 가져오기 실패');
      }

      const data = await response.json();

      if (data && data.prices) {
        setCouponPrices(data.prices);

        // 1인 영화 가격을 couponPrice로 따로 설정
        setCouponPrice(data.prices['1인']);

        // 쿠키에 저장
        Cookies.set('couponCode', couponCodeFromUrl, { path: '/' });
        Cookies.set('couponPrices', JSON.stringify(data.prices), { path: '/' });
        Cookies.set('couponPrice', data.prices['1인'], { path: '/' });
      } else {
        console.error('쿠폰 가격이 데이터에 없습니다:', data);
      }
    } catch (error) {
      console.error('Error fetching coupon price:', error);
    }
  };

  const handleQuantityChange = (productId, quantity) => {
    setSelectedQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: Math.min(Math.max(quantity, 1), products.find(p => p.id === productId).product_quantity)
    }));
  };
  
  const handleProceedToDetailPage = (product) => {
    const selectedProduct = {
      ...product,
      quantity: selectedQuantities[product.id],
      discount: couponApplied && couponProductId === product.id ? couponPrice || 0 : 0,
    };
    navigate(`/mypage/coupon/detailPage/${product.id}`, { state: selectedProduct });
  };

// 배송페이지로 이동하는 함수 수정 
const handleProceedToShipping = (product) => {
  const selectedProduct = {
    ...product,
    quantity: selectedQuantities[product.id],
    // 쿠폰 적용된 상품인 경우에만 쿠폰 할인 적용 (수량과 관계없이 1회만)
    discount: couponApplied && couponProductId === product.id ? couponPrice : 0
  };
  navigate('/mypage/coupon/shipping', { state: selectedProduct });
 };

  const handleApplyCoupon = (productId) => {
    setCouponApplied(true);
    setCouponProductId(productId);
    Cookies.set('couponApplied', 'true', { path: '/' });

  };

  const handleApplyAndBook = async () => {
    try {
      // 쿠폰 코드 설정 (실제 쿠폰 코드로 변경)
      // couponCode 변수가 이미 존재한다고 가정합니다.
      
      // 백엔드로 시간 유효성 검사 요청 보내기
      const response = await fetch('/request/checkTimeValidityForCoupon', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ coupon_code: couponCode }),
      });
  
      if (!response.ok) {
        const errorMessage = await response.text();
        alert(`시간 유효성 검사 실패: ${errorMessage}`);
        return; // 오류 발생 시 함수 종료하여 handleClose를 호출하지 않음
      }
  
      const data = await response.json();
      if (!data.isTimeValid) {
        // 시간 유효하지 않은 경우, 서버에서 받은 메시지를 표시
        console.log(data.message || '사용 가능한 쿠폰입니다. 예매정보를 선택하세요');
      }
      else{
     //쿠폰 사용 불가의 경우
      let successMessage = data.message || '쿠폰 사용 불가 시간입니다.';

      if (data.matchedSetting) {
        const { settingOption, startTime, endTime, startDateTime, endDateTime, weekDays } = data.matchedSetting;
  
        if (settingOption === '매일 특정 시간대') {
          successMessage += `\n사용 불가 시간: ${startTime} ~ ${endTime}`;
        } else if (settingOption === '특정 날짜와 시간대') {
          successMessage += `\n사용 불가 기간: ${startDateTime} ~ ${endDateTime}`;
        } else if (settingOption === '특정 요일과 시간대') {
          successMessage += `\n사용 불가 요일: ${weekDays}\n시간: ${startTime} ~ ${endTime}`;
        }
      }
      alert(successMessage);
      return; // 쿠폰 적용 불가하므로 함수 종료
    }
  
      Cookies.set('couponApplied', 'true', { path: '/' });
      handleClose();
    } catch (error) {
      console.error('시간 유효성 검사 중 오류 발생:', error);
      alert('시간 유효성 검사 중 오류가 발생했습니다.');
    }
  };
  return (
    <Container>
      <Title>잠깐! 예매권으로 상품 구입이 가능한 거 아셨나요?!</Title>
      <ProductList>
        {products.slice(0, 3).map((product) => (
          <ProductCard
            hidden={couponApplied && couponProductId !== product.id}
            key={product.id}
            >
            <ProductImage src={`/images/product/${encodeURIComponent(product.product_name)}.webp`} alt={product.product_name} />
            <ProductDetails>
              <ProductName>{product.product_name}</ProductName>
              <ProductPrice>
                가격: {product.selling_price.toLocaleString()}원
                {!couponApplied && (
                  <ApplyAndBookButton onClick={() => handleApplyCoupon(product.id)}>
                    쿠폰 적용
                  </ApplyAndBookButton>
                )}
              </ProductPrice>
              <ProductQuantity>
                수량:
                <QuantityButton
                  onClick={() => handleQuantityChange(product.id, selectedQuantities[product.id] - 1)}
                  disabled={selectedQuantities[product.id] <= 1}
                >
                  -
                </QuantityButton>
                <QuantityInput
                  type="number"
                  value={selectedQuantities[product.id]}
                  onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value))}
                  min="1"
                  max={product.product_quantity}
                />
                <QuantityButton
                  onClick={() => handleQuantityChange(product.id, selectedQuantities[product.id] + 1)}
                  disabled={selectedQuantities[product.id] >= product.product_quantity}
                >
                  +
                </QuantityButton>
              </ProductQuantity>
              <TotalPrice>
                총 가격: {
                  (() => {
                    // 상품 가격 x 수량 계산
                    const productPrice = product.selling_price * selectedQuantities[product.id];
                    // 쿠폰 적용된 상품인 경우에만 쿠폰 할인 적용 (수량과 관계없이 1회만)
                    const discount = couponApplied && couponProductId === product.id ? couponPrice : 0;
                    // 최종 가격 = 총 상품가격 - 쿠폰할인가
                    return (productPrice - discount).toLocaleString();
                  })()
                }원
                </TotalPrice>
              <PurchaseButton onClick={() => handleProceedToShipping(product)}>
                결제하기
              </PurchaseButton>
              <PurchaseButton onClick={() => handleProceedToDetailPage(product)}>
                상세보기
              </PurchaseButton>
            </ProductDetails>
          </ProductCard>
        ))}
      </ProductList>
      <ApplyAndBookButton onClick={handleApplyAndBook}>
        예매권 적용하고 영화예매
      </ApplyAndBookButton>
    </Container>
  );
}

// 두 번째 화면: 배송지 입력 화면
function ShippingInfo() {
  const { state: selectedProduct } = useLocation(); // 선택된 상품 정보를 가져옴
  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const navigate = useNavigate();
  const couponApplied = Cookies.get('couponApplied') === 'true';
  const couponCode = Cookies.get('couponCode');


  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const response = await fetch('/request/getUserInfo', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('유저 정보 가져오기 실패');
      }

      const data = await response.json();
      setUserName(data.name); // 유저 이름 설정
      setPhoneNumber(data.phone_number); // 유저 전화번호 설정
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const handleOrder = async () => {
    const orderDetails = {
      ...selectedProduct, // 선택된 상품 정보 포함
      order_quantity: selectedProduct.quantity,
      user_name: userName,
      phone_number: phoneNumber,
      postal_code: postalCode,
      address: address,
      // coupon_code: couponApplied ? couponCode : null, // 쿠폰 코드 포함
      coupon_code: couponCode
    };

    try {
      const response = await fetch('/product/completeSale', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderDetails),
      });

      if (!response.ok) {
        throw new Error('결제 처리 실패');
      }

      navigate('/mypage/coupon/order-complete', { state: { product_name: selectedProduct.product_name, total_price: selectedProduct.selling_price * selectedProduct.quantity - selectedProduct.discount } });
    } catch (error) {
      console.error('Error during order processing:', error);
    }
  };

  return (
    <Container>
      <Title>배송지 정보 입력</Title>
      <FormContainer>
        <FormGroup>
          <Label>유저 이름</Label>
          <Input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <Label>휴대폰 번호</Label>
          <Input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <Label>우편번호</Label>
          <Input
            type="text"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <Label>배송지</Label>
          <Input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </FormGroup>

        <SubmitButton onClick={handleOrder}>
          주문하기
        </SubmitButton>
      </FormContainer>
    </Container>
  );
}

// 세 번째 화면: 주문 완료 및 무통장입금 정보
function OrderComplete() {
  const location = useLocation();
  const { product_name, total_price } = location.state || {};

  return (
    <Container>
      <Title>주문이 완료되었습니다.</Title>
      {product_name && total_price && (
        <>
          <p>상품명: {product_name}</p>
          <p>결제 금액: {total_price.toLocaleString()}원</p>
        </>
      )}
      <p>예금주명: 씨네체인</p>
      <p>무통장입금 계좌번호: 기업은행 01077777777</p>
      <p>무통장입금 확인 시 배송이 시작됩니다.</p>
    </Container>
  );
}

// 네 번째 화면: 주문 완료 및 무통장입금 정보
const ProductDetailPage = () => {

  const { id } = useParams(); // URL에서 상품 ID를 받아옵니다.
  console.log('상품 번호:'+id );
  const { state: productDataFromState } = useLocation(); // 이전 페이지에서 전달된 상품 데이터
  const [productData, setProductData] = useState(productDataFromState || null);
  const [loading, setLoading] = useState(!productDataFromState);

  useEffect(() => {
    console.log('상품 조회시작');
    console.log(JSON.stringify(productDataFromState));
    
      // 상품 상세 정보 가져오기
      const fetchProductDetail = async () => {
        try {
          console.log('products:호출시작'+id);
          const response = await fetch(`/order/products/${id}`);
          if (!response.ok) {
            if (response.status === 404) {
              // 상품을 찾을 수 없을 경우
              setProductData(null);
            }
            throw new Error('상품 정보를 가져올 수 없습니다.');
          }
          const data = await response.json();
          console.log('상품 정보:', JSON.stringify(data));
          if (data) {
            setProductData(data);
          } else {
            setProductData(null);
          }
        } catch (error) {
          console.error('오류 발생:', error);
          setProductData(null);
        } finally {
          setLoading(false);
        }
      };

      fetchProductDetail();
  }, [id, productDataFromState]);

  if (loading) {
    return <p>로딩 중입니다...</p>;
  }

  if (!productData) {
    return <p>상세 페이지가 존재하지 않습니다.</p>;
  }

  // 이미지 URL 배열 생성 (upload 필드가 존재하는지 확인)
  const imageUrls = productData.upload
    ? productData.upload.split(',').map((url) => url.trim())
    : [];

  return (
<div style={{ padding: "20px" }}>
 <h1 style={{ marginBottom: "24px" }}>{productData.product_name}</h1>
 {imageUrls.length > 0 ? (
   <div style={{ 
     display: 'flex', 
     overflowX: 'scroll',
     padding: "16px 0",
     gap: "16px"
   }}>
     {imageUrls.map((url, index) => (
       <img
         key={index}
         src={url}
         alt={`상품 이미지 ${index + 1}`}
         style={{ 
           width: '100%', 
           height: 'auto',
           borderRadius: "8px"
         }}
       />
     ))}
   </div>
 ) : (
   <p style={{ margin: "16px 0" }}>이미지가 없습니다.</p>
 )}
</div>
  );
};



export { ShippingInfo, OrderComplete , ProductDetailPage };
