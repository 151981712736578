import { useLocation } from 'react-router-dom';

// 쿼리 파라미터를 쉽게 가져오기 위한 훅
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// 결제 실패 페이지 컴포넌트
export function PaymentFail() {
  let query = useQuery();
  const message = query.get('message'); // 실패 메시지
  const errorCode = query.get('errorCode'); // 오류 코드 (예시)
  const transactionId = query.get('transactionId'); // 거래 ID (예시)

  return (
    <div>
      <h1>Payment Failed</h1>
      <p>{message}</p>
      {errorCode && <p>Error Code: {errorCode}</p>}
      {transactionId && <p>Transaction ID: {transactionId}</p>}
    </div>
  );
}