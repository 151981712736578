import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Button } from '@mui/material';

const PreviewWrapper = styled.div`
  background-color: #F2F2F5; /* 배경색 변경 */
  border: 1px solid #DDDDDD; /* 테두리 추가 */
  border-top: 2px solid black; /* 상단 테두리를 검정색으로 변경 */
  padding: 0 10px;
  height: auto; /* height auto to adjust for content size */

  .preview_play {
    /* 추가 스타일 적용 가능 */
  }

  .preview_play h3 {
    font-size: 1rem;
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .preview_title_container {
    display: flex;
    align-items: center;
  }

  .preview_title {
    color: #E09C13; /* HOT MOVIE 제목 색상 */
    font-family: NanumGothic;
    font-weight: bold;
    margin-right: 5px;
  }

  .preview_subtitle {
    color: #000; /* PREVIEW 제목 색상 */
    font-family: NanumGothic;
    font-weight: bold;
  }

  .commingsoon_video {
    position: relative;
    width: 354px; /* 고정된 너비 */
    height: 260px; /* 고정된 높이 */
    margin-bottom: 20px;
    margin: 0 auto; /* 가운데 정렬 추가 */
  }

  .commingsoon_video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .info_section {
    width: calc(100% - 374px); /* 고정 비디오 옆에 맞도록 너비 조정 */
    padding-left: 5px;
    font-size: 0.7em; /* 글씨 크기 70%로 줄임 */
  }

  .info_section table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .info_section th,
  .info_section td {
    padding: 5px;
    border: 1px solid #ccc;
    text-align: left;
    font-size: small;
  }

  .info_section th {
    background-color: #F6F6F6; /* th 배경색 변경 */
  }

  .info_section th:first-child,
  .info_section td:first-child {
    border-left: 2px solid #555555; /* 테이블의 가장 왼쪽 줄만 볼드 처리 */
  }

  .btn_detail {
    margin-bottom: 20px;
    display: inline-block; /* Use inline-block to maintain natural width */
    text-align: left; /* 버튼을 왼쪽 정렬 */
  }

  .clear {
    clear: both;
  }

  .flex_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow items to wrap for smaller screens */
  }

  /* 600px 이하에서 스타일 조정 */
  @media (max-width: 600px) {
    .flex_container {
      flex-direction: column; /* 수직 방향으로 정렬 */
      align-items: center; /* Center-align items if needed */
    }

    .info_section {
      margin-top: 10px;
      width: 100%; /* info_section이 전체 너비 사용 */
      padding-left: 0; /* 좌측 패딩 제거 */
      margin-bottom: 20px; /* Add spacing between elements */
    }

    .btn_detail {
      margin-left: 0; /* Ensure button aligns left */
      margin-right: auto; /* Ensure the button is left-aligned */
      width: auto; /* Keep button width natural */
      display: inline-block; /* Ensure the button maintains its natural width */
    }
  }
`;

export default function AutoHotMoviePreview() {
  const [movieData, setMovieData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('/movieInfo/fetch-top-movie');
        const data = await response.json();
        setMovieData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);

  if (!movieData) {
    return <div>Loading...</div>;
  }

  return (
    <PreviewWrapper>
      <div className="preview_play">
        <h3 className="preview_title_container">
          <span className="preview_title">HOT MOVIE</span>
          <span className="preview_subtitle">PREVIEW</span>
        </h3>
        <div className="flex_container">
          <div className="commingsoon_video">
            {movieData.youtube_url ? (
              <iframe
                id="video"
                src={movieData.youtube_url}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            ) : (
              <p>유튜브 예고편을 찾을 수 없습니다.</p>
            )}
          </div>
          <div className="info_section">
            <table>
              <thead></thead>
              <colgroup>
                <col style={{ width: '25%' }} />
                <col style={{ width: '75%' }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>감&nbsp;독</th>
                  <td>{movieData.director}</td>
                </tr>
                <tr>
                  <th>출&nbsp;연</th>
                  <td>{movieData.lead_actor}</td>
                </tr>
                <tr>
                  <th>개&nbsp;봉</th>
                  <td>{new Date(movieData.release_date).toLocaleDateString()}</td>
                </tr>
              </tbody>
            </table>
            <Button
              component={Link}
              to={`movie/info/details/${movieData.movie_code}`}
              variant="contained"
              style={{
                fontSize: '12px',
                backgroundColor: '#FFC107', /* 버튼 배경색 */
                color: '#FFF', /* 폰트 색상 */
                textTransform: 'none',
                marginLeft: 0, /* Ensure button aligns left */
                marginRight: 'auto', /* Ensure button aligns left without stretching */
              }}
              className="btn_detail"
            >
              상세보기
            </Button>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    </PreviewWrapper>
  );
}
