import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function PaymentSuccess() {
  const query = useQuery();
  const navigate = useNavigate();
  
  const REP_CODE = query.get('REP_CODE'); // 성공 코드
  const REP_MSG = query.get('REP_MSG'); // 응답 메시지
  const REP_AUTH_NO = query.get('REP_AUTH_NO'); // 승인 번호

  // 예매하기 페이지로 이동하는 함수
  const handleGoToBooking = () => {
    navigate('/booking'); // 예매하기 페이지 경로로 이동 (필요에 맞게 수정)
  };

  return (
    <SuccessContainer>
      <Title>결제가 성공적으로 완료되었습니다!</Title>
      <Details>
        <DetailItem>응답 코드: {REP_CODE}</DetailItem>
        <DetailItem>응답 메시지: {REP_MSG}</DetailItem>
        <DetailItem>승인 번호: {REP_AUTH_NO}</DetailItem>
      </Details>
      <Button onClick={handleGoToBooking}>예매하기 페이지로 가기</Button>
    </SuccessContainer>
  );
}

// 스타일 정의
const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f8ff;
  padding: 20px;
`;

const Title = styled.h1`
  color: #4caf50;
  font-size: 2rem;
  margin-bottom: 20px;
`;

const Details = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px;
`;

const DetailItem = styled.p`
  font-size: 1rem;
  color: #333;
  margin: 10px 0;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #45a049;
  }
`;
