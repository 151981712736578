import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { App, Popup } from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./AuthContext";
import { PopupProvider } from "./PopupContext";
const url = new URL(window.location.href);
const isCouponPopup = url.searchParams.get("issetcoupon") === "false";
const isProductPopup = url.searchParams.has("couponCode");

const root = ReactDOM.createRoot(document.getElementById("root"));

if (isCouponPopup || isProductPopup) {
  root.render(
    <RecoilRoot>
       <BrowserRouter future={{ 
          v7_startTransition: true,
          v7_relativeSplatPath: true 
        }}>
        <AuthProvider>
          <Popup />
        </AuthProvider>
      </BrowserRouter>
    </RecoilRoot>
  );
} else {
  root.render(
    <RecoilRoot>
   <BrowserRouter future={{ 
          v7_startTransition: true,
          v7_relativeSplatPath: true 
        }}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </RecoilRoot>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
