import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../../AuthContext';

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  background-color: #fff;
`;

const FormWrapper = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  margin: 20px 0;
  text-align: left;
  border-bottom: 1px solid #DDDDDD;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid #CC9933;
  margin-bottom: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const LabelField = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  height: 100%;
`;

const InputField = styled.div`
  margin-left: 20px;
  flex: 2;
  display: flex;
  align-items: center;
  height: 100%;
`;

const RedStar = styled.span`
  color: red;
  margin-right: 5px;
`;

const Label = styled.label`
  font-weight: bold;
  text-align: left;
  display: flex;
  align-items: center;
`;

const Select = styled.select`
  width: 200px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: left;
`;

const Input = styled.input`
  width: 200px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #FFC107;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ThinDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 10px;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 40px;
  background-color: #DDDDDD;
  margin: 0 10px;
`;

const bankOptions = [
  '경남',
  '광주',
  '국민',
  '기업',
  '농협',
  '대구',
  '부산',
  '산업',
  '새마을금고',
  '수협',
  '신한',
  '신협',
  '씨티',
  '우리',
  '우체국',
  '전북',
  '제주',
  '카카오뱅크',
  '하나',
  'KEB하나',
  'SC'
];

export default function MyPageUserBank() {
  const [formData, setFormData] = useState({
    bank_name: '',
    account_number: '',
    account_holder_name: ''
  });

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/user/user/login');
    } else {
      fetch('/request/getUserInfo')
        .then((res) => res.json())
        .then((data) => {
          setFormData({
            bank_name: data.bank_name || '',
            account_number: data.account_number || '',
            account_holder_name: data.account_holder_name || ''
          });
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [user, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('/request/updateUserBankInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert('정보가 성공적으로 업데이트되었습니다.');
        } else {
          alert('정보 업데이트에 실패했습니다.');
        }
      })
      .catch((error) => {
        console.error('Error updating user info:', error);
        alert('서버 에러가 발생했습니다. 다시 시도해주세요.');
      });
  };

  return (
    <Container>
      <FormWrapper>
        <Title>환불계좌관리</Title>
        <Divider />
        <form onSubmit={handleSubmit}>
          <FieldContainer>
            <LabelField>
              <Label>은행명</Label>
            </LabelField>
            <VerticalDivider />
            <InputField>
              <Select name="bank_name" value={formData.bank_name} onChange={handleChange} required>
                <option value="">은행을 선택하세요</option>
                {bankOptions.map((bank) => (
                  <option key={bank} value={bank}>{bank}</option>
                ))}
              </Select>
            </InputField>
          </FieldContainer>
          <ThinDivider />
          <FieldContainer>
            <LabelField>
              <Label>계좌번호</Label>
            </LabelField>
            <VerticalDivider />
            <InputField>
              <Input
                type="text"
                name="account_number"
                value={formData.account_number}
                onChange={handleChange}
                required
              />
            </InputField>
          </FieldContainer>
          <ThinDivider />
          <FieldContainer>
            <LabelField>
              <Label>예금주명</Label>
            </LabelField>
            <VerticalDivider />
            <InputField>
              <Input
                type="text"
                name="account_holder_name"
                value={formData.account_holder_name}
                onChange={handleChange}
                required
              />
            </InputField>
          </FieldContainer>
          <ButtonContainer>
            <Button type="submit">확인</Button>
          </ButtonContainer>
        </form>
      </FormWrapper>
    </Container>
  );
}
