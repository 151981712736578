// src/components/MaintenancePopup.js
import React from "react";
import styled, { keyframes } from "styled-components";
import repair from "../../repair.png";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const Content = styled.div`
  text-align: center;
  color: #fff;
  padding: 40px;
  background: linear-gradient(135deg, #1e1e1e, #2e2e2e);
  border-radius: 10px;
  max-width: 500px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
`;

const Heading = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
  color: #ffcc00;
`;

const Paragraph = styled.p`
  font-size: 18px;
  margin-bottom: 10px;
`;

const MaintenancePopup = () => (
  <Overlay>
    <Content>
      <Heading>🚧 점검 중 🚧</Heading>
      <img src={repair} width={400} height={500}></img>
      {/* <Paragraph>현재 웹사이트는 점검 중입니다.</Paragraph>
      <Paragraph>기간 2024-10-25 오전 00:00 ~ 2024-10-28 오전 10:00 까지</Paragraph>
      <Paragraph>관리자만 접근할 수 있습니다.</Paragraph> */}
    </Content>
  </Overlay>
);

export default MaintenancePopup;
