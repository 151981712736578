import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FormBox = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  width: 800px;
  margin-top: 30px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Header = styled.div`
  position: relative;
  text-align: left;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 0;
  display: inline;

  @media (max-width: 768px) {
    display: block;
  }
`;

const SubTitle = styled.p`
  font-size: 0.875rem;
  color: #555;
  margin: 0;
  display: inline;
  margin-left: 15px;

  @media (max-width: 768px) {
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid #CC9933;
  margin: 20px 0;
`;

const HeaderDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
`;

const LightDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
  margin: 10px 0;
`;

const Content = styled.div`
  padding: 20px;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const InputField = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;

  label {
    width: 80px;
    margin-right: 10px;
    font-size: 0.8rem;
    color: #333;
  }

  input {
    max-width: 250px;
    flex: 1;
    height: 20px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.8rem;
    color: #333;
  }

  small {
    margin-left: 10px;
    font-size: 0.8rem;

    @media (max-width: 600px) {
      position: absolute;
      top: 100%;
      left: 0;
      margin-left: 10px;
      margin-top: 30px;
    }
  }
`;

const PasswordResetButton = styled.button`
  height: 40px;
  background-color: #FFC107;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
  margin-top: 30px;

  &:hover {
    background-color: #0056b3;
  }
`;

const PasswordResetLink = styled(Link)`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  margin-top: 10px;
  display: block;
  font-size: 0.8rem;

  &:hover {
    text-decoration: underline;
  }
`;

const Message = styled.p`
  color: red;
  text-align: center;
`;

function PasswordResetForm() {
  const [resetName, setResetName] = useState("");
  const [resetPhone, setResetPhone] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [inputCode, setInputCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [step, setStep] = useState("request"); // 단계: request -> verify -> reset
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const requestVerificationCode = async () => {
    try {
      const response = await fetch("/request/req_verification", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: resetName, phone: resetPhone }),
      });
      if (response.ok) {
        const data = await response.json(); // 서버로부터 인증번호 데이터 받기
        setVerificationCode(data.verificationCode); // 인증번호를 상태에 저장
        setStep("verify");
        setMessage("인증번호가 발송되었습니다.");
      } else {
        setMessage("인증번호 발송에 실패했습니다.");
      }
    } catch (error) {
      setMessage("요청 중 오류가 발생했습니다.");
    }
  };

  const verifyCode = async () => {
    if (verificationCode === inputCode) {
      try {
        const response = await fetch("/request/new_password", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ name: resetName, phone: resetPhone }),
        });
  
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            setMessage("새 비밀번호가 등록된 휴대폰으로 전송되었습니다.");
            setStep("complete");
          } else {
            setMessage("새 비밀번호 생성에 실패했습니다.");
          }
        } else {
          setMessage("서버 오류가 발생했습니다.");
        }
      } catch (error) {
        setMessage("요청 중 오류가 발생했습니다.");
      }
    } else {
      setMessage("인증번호가 일치하지 않습니다.");
    }
  };

 

  return (
    <Container>
      <FormBox>
        <Header>
          <Title>비밀번호 재설정</Title>
          <SubTitle>가입시 등록한 휴대폰번호로 새 비밀번호를 발송해 드립니다.</SubTitle>
          <HeaderDivider />
        </Header>
        <Divider />
        <Content>
          <Message>{message}</Message>
          {step === "request" && (
            <>
              <InputField>
                <label>이름</label>
                <input
                  type="text"
                  required
                  value={resetName}
                  onChange={(e) => setResetName(e.target.value)}
                />
              </InputField>
              <LightDivider />
              <InputField>
                <label>휴대폰번호</label>
                <input
                  type="text"
                  required
                  value={resetPhone}
                  onChange={(e) => setResetPhone(e.target.value)}
                />
                <small>("-"를 빼고 입력해주세요. 예:01099551111)</small>
              </InputField>
              <LightDivider />
              <PasswordResetButton onClick={requestVerificationCode}>
                인증번호 요청
              </PasswordResetButton>
            </>
          )}
          {step === "verify" && (
            <>
              <InputField>
                <label>인증번호</label>
                <input
                  type="text"
                  required
                  value={inputCode}
                  onChange={(e) => setInputCode(e.target.value)}
                />
              </InputField>
              <PasswordResetButton onClick={verifyCode}>인증번호 확인</PasswordResetButton>
            </>
          )}
          <PasswordResetLink to="/login">로그인 화면으로 돌아가기</PasswordResetLink>
        </Content>
      </FormBox>
    </Container>
  );
}

export default PasswordResetForm;
