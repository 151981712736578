import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const LoginCheck = styled.div`
  text-align: center;
  margin-bottom: 20px;

  p {
    color: #FF661D;
    font-size: 1rem;
  }
`;

const StyledLoginButton = styled(Link)`
  display: inline-block;
  background-color: #FFC107;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 10px;
  text-decoration: none; /* 링크 스타일 제거 */

  &:hover {
    background-color: #FFC107;
    opacity: 0.7;
  }
`;

const Terms = styled.div`
  width: 100%;
  margin-bottom: 20px;

  textarea {
    width: 100%;
    height: 150px;
    overflow-y: scroll;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.8rem;
    margin-bottom: 10px;
    resize: none;
  }

  label {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    color: #3985D1;

    input {
      margin-right: 5px;
    }
  }
`;

const UserInfo = styled.div`
  width: 100%;
  margin-bottom: 20px;

  h2 {
    color: #FF661D;
    border-bottom: 2px solid #CC9933;
    padding-bottom: 5px;
    text-align: center; /* 제목을 중앙 정렬 */
  }

  form {
    display: flex;
    flex-direction: column; /* 세로 정렬 */
    align-items: center; /* 폼 요소들을 중앙으로 정렬 */
  }

  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    justify-content: center;
    position: relative; /* Position relative for absolute button positioning */
    max-width: 300px;
  }

  label {
    width: 80px;
    font-size: 0.8rem;
    color: #333;
  }

  input {
    flex: 1;
    height: 40px;
    padding: 5px 50px 5px 10px; /* Adjust padding for button space */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.8rem;
    max-width: 300px;
    box-sizing: border-box; /* Include padding and border in width */
  }
  .verification-ok-button {
    position: absolute; /* Position button inside input */
    right: 10px; /* Adjust right position for input integration */
    top: 50%;
    transform: translateY(-50%);
    height: 25px;
    background-color: #FFC107;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.7rem;
    cursor: pointer;
    padding: 0 10px;

    &:hover {
      background-color: #0056b3;
    }
}
  .verification-button {
    position: absolute; /* Position button inside input */
    right: 10px; /* Adjust right position for input integration */
    top: 50%;
    transform: translateY(-50%);
    height: 25px;
    background-color: #FFC107;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.7rem;
    cursor: pointer;
    padding: 0 10px;
    display: none; /* Hide button initially */

    &:hover {
      background-color: #0056b3;
    }
    
  }
`;

const RegisterButton = styled.button`
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#FFC107")};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-size: 1em;
  margin-top: 20px; /* Add some space above the button */
  margin-bottom: 20px;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? "#ccc" : "#0056b3")};
  }
`;

const Message = styled.p`
  color: red;
  text-align: center;
`;

const StyledLabel = styled.label`
  width: 90px !important;
  margin-right: 2px !important;
`;

function RegisterForm() {
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [newPassword, setNewPassword] = useState(""); // 비밀번호 필드 추가
  const [confirmNewPassword, setConfirmPassword] = useState(""); // 비밀번호 확인 필드 추가
  const [message, setMessage] = useState("");
  const [useConditions, setUseConditions] = useState("");
  const [personalInfo, setPersonalInfo] = useState("");

  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const [isPrivacyAgreed, setIsPrivacyAgreed] = useState(false);

  //인증처리
  const [showVerificationInput, setShowVerificationInput] = useState(false);
  const [inputVerificationCode, setInputVerificationCode] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [step, setStep] = useState("request"); // 단계: request -> verify -> reset
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("/registerText/useinfo.txt")
      .then((response) => response.text())
      .then((data) => setUseConditions(data))
      .catch((error) => console.error("Error fetching useConditions:", error));

    fetch("/registerText/privacy.txt")
      .then((response) => response.text())
      .then((data) => setPersonalInfo(data))
      .catch((error) => console.error("Error fetching personalInfo:", error));
  }, []);

  //인증기능
  // 인증번호 확인 함수
const verifyCode = () => {
  if (inputVerificationCode === verificationCode) {
    setIsVerified(true);
    setMessage("인증이 완료되었습니다.");
    setShowVerificationInput(false);
  } else {
    setMessage("인증번호가 일치하지 않습니다.");
  }
};
  const requestVerificationCode = async () => {
    try {
      const response = await fetch("/request/req_phone_verification", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: username, phone: phone }),
      });
      if (response.ok) {
        const data = await response.json(); // 서버로부터 인증번호 데이터 받기
        setVerificationCode(data.verificationCode); // 인증번호를 상태에 저장
        setStep("verify");
        setMessage("인증번호가 발송되었습니다.");
      } else {
        setMessage("인증번호 발송에 실패했습니다.");
      }
    } catch (error) {
      setMessage("요청 중 오류가 발생했습니다.");
    }
  };

  const validateForm = () => {
    if (!username || username.length === 0) {
      setMessage("사용자 이름을 입력해주세요.");
      return false;
    }
  
    if (!phone || phone.length === 0) {
      setMessage("전화번호를 입력해주세요.");
      return false;
    }
  
    if (!newPassword || newPassword.length === 0) {
      setMessage("비밀번호를 입력해주세요.");
      return false;
    }
  
    if (!confirmNewPassword || confirmNewPassword.length === 0) {
      setMessage("비밀번호를 확인해주세요.");
      return false;
    }
  
    if (newPassword !== confirmNewPassword) {
      setMessage("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
      return false;
    }
  
    if (!isTermsAgreed) {
      setMessage("이용 약관에 동의해주세요.");
      return false;
    }
  
    if (!isPrivacyAgreed) {
      setMessage("개인정보 처리 방침에 동의해주세요.");
      return false;
    }
  
    // 모든 조건이 만족되면 true 반환
    return true;
  };

  const handleKeyDown = (e) => {
    const key = e.key;
    if (!/[0-9]/.test(key) && 
        key !== 'Backspace' && 
        key !== 'Delete' && 
        key !== 'ArrowLeft' && 
        key !== 'ArrowRight' &&
        key !== 'Tab'    // Tab 키 추가
    ) {
        e.preventDefault();
    }
  };
  
  // 전화번호 입력 시 자동으로 하이픈 추가하는 함수
  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // 숫자만 추출
    let formattedNumber = '';
    if (value.length <= 3) {
      formattedNumber = value;
    } else if (value.length <= 7) {
      formattedNumber = `${value.slice(0, 3)}-${value.slice(3)}`;
    } else {
      formattedNumber = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7, 11)}`;
    }
    setPhone(formattedNumber); // 상태 업데이트
  };



  const handleSubmitRegister = async (event) => {
    event.preventDefault();
  
    if (!validateForm()) return;
  
    try {
      const response = await fetch("/request/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: username,
          phone_number: phone.replace(/-/g, ''), // 하이픈 제거 후 전송
          new_password: newPassword,
          confirm_new_password: confirmNewPassword,
        }),
      });
  
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
  
      alert(`${username}님, 회원가입이 성공적으로 완료되었습니다.`);
      navigate("/user/user/login");
    } catch (error) {
      console.error("회원가입 실패: ", error.message);
      setMessage(error.message);
    }
  };

  return (
    <Container>
      <LoginCheck>
        <p>사용자 정보를 입력하신 적이 있으면 로그인을 해주세요.</p>
        <StyledLoginButton to="/user/user/login">로그인하기</StyledLoginButton>
      </LoginCheck>
      <Terms>
        <textarea readOnly value={useConditions}></textarea>
        <label>
          <input
           type="checkbox"
           required
           checked={isTermsAgreed}
           onChange={() => setIsTermsAgreed(!isTermsAgreed)} 
           />{" "}
           이용약관 동의
        </label>
      </Terms>
      <Terms>
        <textarea readOnly value={personalInfo}></textarea>
        <label>
          <input
           type="checkbox"
            required 
            checked={isPrivacyAgreed}
            onChange={() => setIsPrivacyAgreed(!isPrivacyAgreed)}
            />{" "}
           개인정보 수집 및 이용 동의
        </label>
      </Terms>
      <UserInfo>
        <h2>사용자정보 입력하기</h2>
        <form onSubmit={handleSubmitRegister}>
          <Message>{message}</Message>
          <div className="input-group">
            <label>이름</label>
            <input
              type="text"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label>휴대폰번호</label>
            <input
              type="text"
              required
              value={phone}
              onChange={handlePhoneChange} // 수정: 하이픈 추가 로직 적용
              onKeyDown={handleKeyDown} // 입력 제한
              maxLength="13" // 형식 제한
            />
            <button
              type="button"
              className="verification-button"
              onClick={async () => {
                await requestVerificationCode();
                setShowVerificationInput(true);
              }}
              style={{ display: phone ? "block" : "none" }}
            >
              인증
            </button>
          </div>
          {/* 인증번호 입력 필드 */}
          {showVerificationInput && !isVerified && (
            <div className="input-group">
              <label>인증번호</label>
              <input
                type="text"
                value={inputVerificationCode}
                onChange={(e) => setInputVerificationCode(e.target.value)}
                maxLength="6"
              />
              <button
                type="button"
                className="verification-ok-button"
                onClick={verifyCode}
              >
                인증확인
              </button>
            </div>
          )}
          <div className="input-group">
            <label>비밀번호</label>
            <input
              type="password"
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="input-group">
          <StyledLabel>비밀번호 확인</StyledLabel>
            <input
              type="password"
              required
              value={confirmNewPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <RegisterButton
           type="submit"
           disabled={!isTermsAgreed || !isPrivacyAgreed || !isVerified}
           >
            회원가입
          </RegisterButton>
        </form>
      </UserInfo>
    </Container>
  );
}

export default RegisterForm;
