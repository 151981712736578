/**
 * 작성일 : 2024-11-12
 * 작성자 : 양다빈
 * 유저 세션 저장 및 확인
 */

export const setSessionFunc = async (data) => {
  try {
    const response = await fetch("/user/set_session", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        data: data,
      }),
    });

    const returnData = await response.json();
    // console.log(`***** setSessionFunc *****`);
    // console.log(returnData);
    // console.log(`***** setSessionFunc *****`);
    if (response.ok) {
      return returnData;
    } else {
      // 로그인 실패 처리
      if (response.status === 401) {
      }
    }
  } catch (e) {
    console.log(`setSessionFunc Error : ${e}`);
  }
};

export const getSessionFunc = async (phone_number) => {
  try {
    const response = await fetch("/user/get_session", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: phone_number }),
    });

    const returnData = await response.json();
    //console.log(`***** getSessionFunc *****`);
    //console.log(returnData);
    //console.log(`***** getSessionFunc *****`);
    if (response.ok) {
      return returnData;
    } else {
      // 로그인 실패 처리
      if (response.status === 401) {
      }
    }
  } catch (e) {
    console.log(`getSessionFunc Error : ${e}`);
  }
};

export const delSessionFunc = async (id) => {
  try {
    const response = await fetch("/user/del_session", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    });

    const returnData = await response.json();
    //console.log(`***** delSessionFunc *****`);
    //console.log(returnData);
    //console.log(`***** delSessionFunc *****`);
    if (response.ok) {
      return returnData;
    } else {
      // 로그인 실패 처리
      if (response.status === 401) {
      }
    }
  } catch (e) {
    console.log(`getSessionFunc Error : ${e}`);
  }
};
