import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../AuthContext';
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: left;
  width: 100%;
  box-sizing: border-box;
`;

const PostContainer = styled.div`
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  max-width: 1200px;
  background-color: white;
  padding : 20px;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderItem = styled.h4`
  margin-right: 1rem;
  &:last-child {
    margin-right: 0;
  }
`;

const Title = styled.h2`
  color: #333;
  text-align: left;
`;

const Content = styled.span`
  color: #333;
  text-align: left;
  word-wrap: break-word;
  min-height:300px;
  display:flex;
  flex-direction:column;
`;

const CommentCard = styled.div`
  background: white;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 14px;
`;

const ReplyCard = styled.div`
  margin-left: 20px;
  background-color: #f8f9fa;
  border-left: 3px solid #007bff;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 12px;
`;

const DeleteButton = styled.button`
  background-color: #dc3545;
  font-size: 12px;
  padding: 4px 8px;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
  &:hover {
    background-color: #c82333;
  }
`;

const EditButton = styled.button`
  background-color: #007bff;
  font-size: 12px;
  padding: 4px 8px;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
  transition: background-color 0.3s, transform 0.1s;
  &:hover {
    background-color: #0056b3;
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(1px);
  }
`;

const TextArea = styled.textarea`
  width: calc(100% - 60px);
  border: 1px solid #ced4da;
  border-radius: 4px;
  resize: none;
  padding:10px;
  margin-right: 8px;
  font-size: 14px;
`;

const EditTextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  resize: vertical;
  margin-right: 8px;
  font-size: 14px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
`;


const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 18px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  margin-left: 8px;
  &:hover {
    background-color: #0056b3;
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(1px);
  }
`;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
};

const Post = ({ post, comments, setComments ,fetchData}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [isFileListVisible, setIsFileListVisible] = useState(false);

  const [updateEndpoint, setUpdateEndpoint] = useState('');

  // useEffect로 마운트 시 updateEndpoint 설정
  useEffect(() => {
    let endpoint = '';
    if (location.pathname.includes('/cscenter/board/QaBoard')) {
      endpoint = '/updateFAQ';
    } else if (location.pathname.includes('/cscenter/board/NoticeBoard')) {
      endpoint = '/updateNotice';
    } else if (location.pathname.includes('/cscenter/board/PurchaseBoard')) {
      endpoint = '/updatePurchase';
    } else if (location.pathname.includes('/cscenter/board/OneToOneBoard')) {
      endpoint = '/updateOneToOne';
    }
    setUpdateEndpoint(endpoint);
  }, [location.pathname]);

  const handleUpdate = () => {
    // navigate로 updateEndpoint를 state에 전달
    navigate(`/cscenter/board/update/${post.id}`, { state: { endpoint:updateEndpoint ,post:post } });
  };
  const handleDelete = async () => {
    let deleteEndpoint = '';
      if (location.pathname.includes('/cscenter/board/QaBoard')) {
      deleteEndpoint = `/request/deleteFAQ/${post.id}`;
    } else if (location.pathname.includes('/cscenter/board/NoticeBoard')) {
      deleteEndpoint = `/request/deleteNotice/${post.id}`;
    } else if (location.pathname.includes('/cscenter/board/PurchaseBoard')) {
      deleteEndpoint = `/request/deletePurchase/${post.id}`;
    } else if (location.pathname.includes('/cscenter/board/OneToOneBoard')) {
      deleteEndpoint = `/request/deleteOneToOne/${post.id}`;
    }

    try {
      const response = await fetch(deleteEndpoint, {
        method: 'DELETE',
      });
      if (response.ok) {
        alert('삭제 완료');
        navigate('/'); // 적절한 경로로 이동
      } else {
        alert('Failed to delete the post');
      }
    } catch (error) {
      console.error('Error deleting post:', error);
      alert('An error occurred while deleting the post');
    }
  };

  // 댓글 삭제 함수
  const deleteComment = async (commentId) => {
    try {
      const response = await fetch(`/request/cinema_delete_comment/${commentId}`, {
        credentials: 'include',
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error(`Failed to delete comment: ${response.statusText}`);
      }

      console.log('Comment deleted successfully');
      setComments(comments.filter(comment => comment.comment_id !== commentId));
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  // 답글 삭제 함수
  const deleteReply = async (commentId, replyId) => {
    try {
      const response = await fetch(`/request/cinema_delete_reply/${replyId}`, {
        credentials: 'include',
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error(`Failed to delete reply: ${response.statusText}`);
      }

      console.log('Reply deleted successfully');
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.comment_id === commentId
            ? {
                ...comment,
                replies: comment.replies.filter((reply) => reply.comment_id !== replyId),
              }
            : comment
        )
      );
    } catch (error) {
      console.error('Error deleting reply:', error);
    }
  };

  // 답글 추가 함수
  const handleAddReply = async (commentIndex, replyText) => {
    if (!replyText || replyText.trim() === "") return;

    try {
      const parentCommentId = comments[commentIndex].comment_id;
      const result = await addReply(parentCommentId, replyText, user?.phone_number);

      if (result) {
        fetchData();
      }
    } catch (error) {
      console.error('Error adding reply:', error);
    }
  };

  // 답글 추가를 위한 API 호출 함수
  const addReply = async (parentCommentId, content, author) => {
    try {
      const response = await fetch('/request/cinema_add_reply', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          board_id: post.board_id, // post 객체에서 가져옴
          post_id: post.id, // post 객체에서 가져옴
          parent_comment_id: parentCommentId,
          content,
          author,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to add reply: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Reply added successfully:', data);
      return data;
    } catch (error) {
      console.error('Error adding reply:', error);
    }
  };

 

  // 댓글 수정 함수
  async function editComment(commentId, content) {
    try {
      const response = await fetch('/request/cinema_update_comment', { // 경로 수정
        credentials: 'include',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          comment_id: commentId,
          content,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to edit comment: ${response.statusText}`);
      }
  
      console.log('Comment edited successfully');
      // 상태 업데이트
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.comment_id === commentId ? { ...comment, content } : comment
        )
      );
    } catch (error) {
      console.error('Error editing comment:', error);
    }
  }

  // 답글 수정 함수
  const handleEditReply = async ( replyId, newContent) => {
    try {
      const response = await fetch('/request/cinema_update_reply', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reply_id: replyId,
          content: newContent,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update the reply');
      }
  
      console.log('Reply updated successfully');
      fetchData(); // 업데이트된 데이터를 다시 가져옴
    } catch (error) {
      console.error('Error updating reply:', error);
    }
  };

  // 파일 리스트 토글 함수 (필요한 경우)
  const toggleFileList = () => {
    setIsFileListVisible(!isFileListVisible);
  };

  // 댓글 입력 관련 상태
  const [newComment, setNewComment] = useState('');

  // 댓글 추가 함수
  const handleAddComment = async () => {
    if (newComment.trim() === '') return;

    try {
      const response = await fetch('/request/cinema_add_comment', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          board_id: post.board_id,
          post_id: post.id,
          content: newComment,
          author: post.author,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to add comment: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Comment added successfully:', data);
      setNewComment('');
      fetchData(); // 댓글 데이터 다시 가져오기
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  return (
    <PostContainer>
      <Header>
        <HeaderLeft>
          {/* {JSON.stringify(post)} */}
          <HeaderItem>작성일: {formatDate(post.created_at)}</HeaderItem>
          <HeaderItem>작성자: {post.author}</HeaderItem>
          <HeaderItem>조회: {post.views}</HeaderItem>
        </HeaderLeft>
        <HeaderRight>
          {user?.phone_number === post.user_id &&<HeaderItem>
            <span onClick={() => handleUpdate()}>
              [수정]
            </span>
          </HeaderItem>}
          {user?.phone_number === post.user_id &&
          <HeaderItem onClick={handleDelete}>[삭제]</HeaderItem>}
        </HeaderRight>
      </Header>
      <hr />
      <Title>{post.title}</Title>
      <Content id='content' dangerouslySetInnerHTML={{ __html: post.content }}></Content>
      {/* 댓글 입력 섹션 */}
      <InputWrapper>
        <TextArea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder={user?.phone_number?"댓글을 입력할 수 있습니다.":"로그인 사용자만 입력이 가능합니다."}
          disabled = {!user?.phone_number}
        />
        <Button onClick={handleAddComment}>Comment</Button>
      </InputWrapper>
      {/* 댓글 목록 렌더링 */}
      {comments.map((comment, index) => (
        <CommentView
          key={index}
          index={index}
          comment={comment}
          onDelete={deleteComment}
          onEdit={editComment}
          onReplyAdd={handleAddReply}
          onReplyDelete={deleteReply}
          onReplyEdit={handleEditReply}
          fetchData={fetchData}
        />
      ))}
    </PostContainer>
  );
};

export default function PostViewer() {
  const { user } = useAuth();
  const { id } = useParams();
  const location = useLocation();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]); // 댓글 상태 추가
    
  const fetchData = async () => {
    let endpoint = '';
    let board_id = '';
    setLoading(true);
  
    // 게시판에 맞는 API 엔드포인트 설정
    switch (true) {
      case location.pathname.includes('/cscenter/board/QaBoard'):
        endpoint = `/request/getFAQPage/${id}`;
        board_id = 'cs_faq';
        break;
      case location.pathname.includes('/cscenter/board/NoticeBoard'):
        endpoint = `/request/getNoticePage/${id}`;
        board_id = 'cs_notice';
        break;
      case location.pathname.includes('/cscenter/board/PurchaseBoard'):
        endpoint = `/request/getPurchasePage/${id}`;
        board_id = 'cs_purchase';
        break;
      case location.pathname.includes('/cscenter/board/OneToOneBoard'):
        endpoint = `/request/getOneToOnePage/${id}`;
        board_id = 'cs_onetoone';
        break;
      default:
        console.error('Invalid board type.');
        setLoading(false);
        return;
    }
  
    try {
      const res = await fetch(endpoint);
      const data = await res.json();
  
      // board_id 추가
      data.board_id = board_id;
      setPost(data); // 게시글 데이터 설정
  
      // 댓글 데이터 가져오기
      const commentsRes = await fetch(`/request/getComments/${id}`);
      const commentsData = await commentsRes.json();
      setComments(commentsData); // 댓글 데이터 설정
    } catch (error) {
      console.error('Error fetching post or comments:', error);
    } finally {
      setLoading(false); // 로딩 완료
    }
  };

  useEffect(() => {
    fetchData(); // fetchData 함수를 호출합니다.
  }, [id, location.pathname]);

  if (loading) {
    return (
      <Container>
        <div>Loading...</div>
      </Container>
    );
  }

  if (!post) {
    return (
      <Container>
        <div>없는 글입니다.</div>
      </Container>
    );
  }

  return (
    <Container>
      <Post post={post} comments={comments} setComments={setComments} fetchData={fetchData}/>
    </Container>
  );
}

function CommentView({ comment, index, onDelete, onEdit, onReplyAdd, onReplyEdit, onReplyDelete,fetchData }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [newCommentContent, setNewCommentContent] = useState(comment?.content);
  const [newReply, setNewReply] = useState('');
  const { user } = useAuth();
  const handleEditToggle = () => {
    if (isEditMode) {
      onEdit(comment.comment_id, newCommentContent); // Save 버튼을 클릭할 때 업데이트 수행
    }
    setIsEditMode(!isEditMode); // Edit 모드 토글
  };

  const handleReplyAdd = () => {
    if (newReply.trim() !== "") {
      onReplyAdd(index, newReply); // `index`와 새 답글을 전달합니다.
      setNewReply(''); // 새 답글 필드를 초기화
    }
  };

  return (
    <CommentCard>
      <div style={{ display: 'flex', justifyContent: 'space-between' ,textAlign:'left'}}>
        <div>
          <strong>{comment.user_id}</strong> <span>{new Date(comment.created_at).toLocaleString()}</span>
        </div>
        {comment.user_id === user?.phone_number &&
        <div>
          <EditButton onClick={handleEditToggle}>
            {isEditMode ? 'Save' : 'Edit'}
          </EditButton>
          <DeleteButton onClick={() => onDelete(comment.comment_id)}>
            Delete
          </DeleteButton>
        </div>
}
      </div>
      {!isEditMode ? (
        <div style={{ textAlign: 'left'}}>{comment.content}</div>
      ) : (
        <>
          <hr />
          <InputWrapper>
            <EditTextArea
              value={newCommentContent}
              onChange={(e) => setNewCommentContent(e.target.value)}
              placeholder="Edit comment..."
            />
          </InputWrapper>
          <hr />
        </>
      )}
      {comment.replies && comment.replies.map((reply, replyIndex) => (
        <ReplyView
          key={replyIndex}
          reply={reply}
          commentId={comment.comment_id}
          onEdit={onReplyEdit}
          onDelete={onReplyDelete}
          fetchData={fetchData}
        />
      ))}
      <InputWrapper>
        <TextArea
          placeholder={user?.phone_number?"대댓글을 입력할 수 있습니다.":"로그인 사용자만 입력이 가능합니다."}
          value={newReply}
          onChange={(e) => setNewReply(e.target.value)}
          disabled = {!user?.phone_number}
        />
        <Button onClick={handleReplyAdd}>
          Reply
        </Button>
      </InputWrapper>
    </CommentCard>
  );
}

function ReplyView({ reply, commentId, onEdit, onDelete,fetchData }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const { user } = useAuth();
  const [newReplyContent, setNewReplyContent] = useState(reply.content);

  const handleEditToggle = () => {
    if (isEditMode) {
      onEdit(reply.comment_id, newReplyContent);
    }
    setIsEditMode(!isEditMode); // Edit 모드 토글
  };

  return (
    <ReplyCard>
      <div style={{ display: 'flex', justifyContent: 'space-between'  ,textAlign:'left'}}>
        <div>
          <strong>{reply.user_id}</strong> <span>{new Date(reply.created_at).toLocaleString()}</span>
        </div>
        {reply.user_id === user?.phone_number &&
        <div>
          <EditButton onClick={handleEditToggle}>
            {isEditMode ? 'Save' : 'Edit'}
          </EditButton>
          <DeleteButton onClick={() => onDelete(commentId, reply.comment_id)}>
            Delete
          </DeleteButton>
        </div>
}
      </div>
      {!isEditMode ? (
        <div style={{textAlign:'left'}}>{reply.content}</div>
      ) : (
        <>
          <hr />
          <EditTextArea
            value={newReplyContent}
            onChange={(e) => setNewReplyContent(e.target.value)}
            placeholder="Edit reply..."
          />
          <hr />
        </>
      )}
    </ReplyCard>
  );
}
