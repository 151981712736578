import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import SubNavigator from '../../components/SubNavigator';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CallIcon from '@mui/icons-material/Call';
import Tooltip from '@mui/material/Tooltip'; // Import Tooltip component

const links = [
  { path: '/mypage/order/3', label: '예매/취소내역', icon: '/images/icon/order.png' },
  { path: '/mypage/user/edit', label: '개인정보관리', icon: PersonIcon },
  { path: '/mypage/coupon', label: '쿠폰관리', icon: '/images/icon/coupon.png' },
  { path: '/mypage/userbank/create', label: '환불계좌관리', icon: AccountBalanceWalletIcon },
];

const GlobalStyle = createGlobalStyle`
  * {
    -webkit-tap-highlight-color: transparent; /* Disable tap highlight color on mobile devices */
  }

  button {
    outline: none; /* Remove default focus outline */
    -webkit-user-select: none; /* Disable text selection */
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const MyPageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 80px); /* Adjusted to remove top navigation height */

  @media (max-width: 580px) {
    flex-direction: column; /* Make the layout vertical on smaller screens */
    width: 100%;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  min-width: 10rem; /* Ensure the navigation has a consistent width */
  box-sizing: border-box; /* Include padding and border in the width */

  @media (max-width: 580px) {
    display: none; /* Hide the navigation on smaller screens */
  }
`;

const Main = styled.main`
  background-color: white;
  flex: 1;
  padding: 10px; /* Ensure the content is not touching the edges */
  margin: 20px; /* Reset margin to avoid additional spacing */
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    margin-top: 0;
    padding-top: 0;
  }
`;

const Title = styled.div`
  font-weight: bold;
  background-color: #ffc107;
  color: #1b1f23;
  font-size: 1.1em; /* 제목 크기, 필요에 따라 조정 */
  text-align: left;
  padding: 10px;
  border-top: 5px solid #dd9d22; /* 상단 테두리 색상 및 두께 */
  width: 10rem; /* Set the same width as Nav to match */
  box-sizing: border-box; /* Include padding and border in the width */
  margin-top: 30px; /* Add top margin to create space above the title */

  @media (max-width: 768px) {
    margin-top: 0;
  }

`;

const pressAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

const MobileNavButton = styled.button`
  display: none;

  @media (max-width: 580px) {
    display: flex; /* Change to flex to center the icon */
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: #ffc107;
    color: #1b1f23;
    border: none;
    border-radius: 50%; /* Make it circular */
    font-weight: bold;
    cursor: pointer;
    z-index: 1000; /* Ensure the button is above other elements */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.1s ease;
    outline: none; /* Remove the focus outline */
    user-select: none; /* Prevent text selection */

    &:active {
      animation: ${pressAnimation} 0.2s;
    }

    &:focus {
      outline: none; /* Remove the focus outline */
    }
  }
`;

const ModalContent = styled.div`
 display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
 position: fixed;
 bottom: 70px;
 left: 20px;
 background-color: white;
 padding: 10px;
 border-radius: 5px;
 box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 z-index: 1001;
`;

const ExpandableButtons = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 80px;
  left: 20px;
  z-index: 1000;
`;

const ExpandedButton = styled.button`
  width: 50px;
  height: 50px;
  margin-top: 10px;
  background-color: #ffc107;
  color: #1b1f23;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  outline: none; /* Remove the focus outline */
  user-select: none; /* Prevent text selection */

  &:active {
    animation: ${pressAnimation} 0.2s;
  }

  &:focus {
    outline: none; /* Remove the focus outline */
  }
`;

const IconImage = styled.img`
  width: 32px;
  height: 32px;
  pointer-events: none; /* Prevents the image from being selected */
`;

export default function MyPage() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const wrapperRef = useRef(null);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const toggleExpand = () => {
    setExpanded(!isExpanded);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      if (!isMobileDevice() && window.innerWidth <= 580) {
        alert("클립보드에 저장되었습니다.");
      }
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleButtonClick = (action) => {
    action();
    setExpanded(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 580) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, [wrapperRef]);

  return (
    <>
      <GlobalStyle />
      <MyPageWrapper>
        <Nav>
          <Title>마이페이지</Title>
          <SubNavigator links={links} />
        </Nav>
        <Main>
          <Outlet />
        </Main>

        <div ref={wrapperRef}>
          <MobileNavButton onClick={toggleExpand}>
            <ListAltIcon fontSize="large" /> {/* Use ListAltIcon here */}
          </MobileNavButton>

          {isExpanded && (
            <ExpandableButtons>
              <Tooltip title="예매/취소내역" placement="top" arrow>
                <ExpandedButton onClick={() => handleButtonClick(() => navigate('/mypage/order/3'))}>
                  <IconImage src="/images/icon/order.png" alt="예매/취소내역" />
                </ExpandedButton>
              </Tooltip>
              <Tooltip title="개인정보관리" placement="top" arrow>
                <ExpandedButton onClick={() => handleButtonClick(() => navigate('/mypage/user/edit'))}>
                  <PersonIcon />
                </ExpandedButton>
              </Tooltip>
              <Tooltip title="쿠폰관리" placement="top" arrow>
                <ExpandedButton onClick={() => handleButtonClick(() => navigate('/mypage/coupon'))}>
                  <IconImage src="/images/icon/coupon.png" style={{transform: 'scale(1.4)'}} alt="쿠폰관리" />
                </ExpandedButton>
              </Tooltip>
              <Tooltip title="환불계좌관리" placement="top" arrow>
                <ExpandedButton onClick={() => handleButtonClick(() => navigate('/mypage/userbank/create'))}>
                  <AccountBalanceWalletIcon />
                </ExpandedButton>
              </Tooltip>
              <Tooltip title="고객센터" placement="top" arrow>
                <ExpandedButton onClick={() => handleButtonClick(() => handleCopyToClipboard('1522-0391'))}>
                  <CallIcon />
                </ExpandedButton>
              </Tooltip>
            </ExpandableButtons>
          )}
        </div>

        <ModalContent $isOpen={isModalOpen}>
          <SubNavigator links={links} onLinkClick={closeModal}/>
        </ModalContent>
      </MyPageWrapper>
    </>
  );
}
