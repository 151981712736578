import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAuth } from '../../AuthContext';

const Container = styled.div`
  background-color: white;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  height: calc(100vh - 80px); /* 화면 전체를 채우도록 설정 */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.form`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;


const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const QuillWrapper = styled.div`
  margin: 1rem 0;
  .ql-editor {
    min-height: 200px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 2rem;
  margin: 0 0.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;

  &.submit {
    background-color: #007bff;
    color: white;
  }

  &.cancel {
    background-color: #ccc;
  }
`;

export default function UpdateEditorLite() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const location = useLocation();
  const { endpoint ,post} = location.state || {};
  const [title, setTitle] = useState(post.title);
  const [content, setContent] = useState(post.content);
  const [date, setDate] = useState(''); // 데이터 로드 후 설정

  const boardType = location.pathname.split('/')[3];
  let fetchUrl = '';


  useEffect(() => {
    fetch(fetchUrl)
      .then((res) => res.json())
      .then((data) => {
        setTitle(data.title);
        setContent(data.content);
        setDate(data.created_at || new Date().toISOString().split('T')[0]);
      })
      .catch((error) => console.error('Error fetching post:', error));
  }, [fetchUrl]);

  if (!user) {
    return <div>로그인이 필요합니다.</div>;
  }
const getBoardName=(endpoint)=>{
  let board_name = '';
  if(endpoint === '/updateNotice')
    board_name = '/NoticeBoard';
  else if(endpoint === '/updatePurchase'){
    board_name = '/PurchaseBoard';
  }
  else if(endpoint === '/updateOneToOne'){
      board_name='/OneToOneBoard';
  }
  else{
    board_name='찾을수 없음';
  }
  return board_name;
}
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title && content) {
      const postData = {
        id:post.id,
        views: post.views, // 기본값 설정
        title:title,
        content:content,
        author: user.name,
        created_at: post.created_at
      };

      try {
        const response = await fetch(`/request`+endpoint+`/${id}`, {
          method: 'POST',
          credentials:'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData)
        });

        if (response.ok) {
          navigate(`/cscenter/board`+getBoardName(endpoint)+'/'+id);
        } else {
          alert('글 수정에 실패했습니다.');
        }
      } catch (error) {
        console.error('Error updating data:', error);
        alert('글 수정 중 오류가 발생했습니다.');
      }
    } else {
      alert('제목과 내용은 필수로 입력해야 합니다.');
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="제목을 입력하세요."
        />

        <QuillWrapper>
          <ReactQuill
            value={content}
            onChange={setContent}
            placeholder="내용을 입력하세요."
          />
        </QuillWrapper>

        <ButtonRow>
          <Button type="submit" className="submit">확인</Button>
          <Button type="button" className="cancel" onClick={handleCancel}>취소</Button>
        </ButtonRow>
      </Form>
    </Container>
  );
}
