import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const GuideWrapper = styled.div`
  background-color: white;
  width: 90%;
  margin-top: 20px;

`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  border-bottom: 1px solid #DDDDDD; /* Add bottom border */

  @media (max-width: 768px) {
    flex-direction: column; /* 작은 화면에서 수직 정렬 */
    align-items: flex-start; /* 왼쪽 정렬 */
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 0;

  @media (max-width: 425px) {
    font-size: 1.5rem; /* 작은 화면에서 폰트 크기 조정 */
  }
`;

const SubTitle = styled.p`
  font-size: 0.875rem;
  color: #555;
  margin-bottom: 5px;
  margin-left: 5px; /* Margin to create space between Title and SubTitle */

  @media (max-width: 768px) {
    margin-left: 0; /* 작은 화면에서 왼쪽 마진 제거 */
    margin-top: 0.5rem; /* Title 아래로 내려가면서 여백 추가 */
  }
`;

const GuideImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
`;

export default function CSCenterGuide() {
  return (
    <GuideWrapper>
      <HeaderWrapper>
        <TitleWrapper>
          <Title>영화예매가이드</Title>
          <SubTitle>고객님의 빠르고 손쉬운 영화예매를 위해 필독하여 주세요.</SubTitle>
        </TitleWrapper>
      </HeaderWrapper>
      <GuideImage src="/images/guide.jpg" alt="guide" />
      <Outlet />
    </GuideWrapper>
  );
}
