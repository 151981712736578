import { atom } from "recoil";

export const HeaderAtom = atom({
    key: 'HeaderAtom',
    default: 0,
});

export const couponPriceAtom = atom({
    key: 'couponPriceAtom', // unique ID (with respect to other atoms/selectors)
    default: 0, // default value (aka initial value)
});
  
export const couponCodeAtom = atom({
    key: 'couponCodeAtom',
    default: '', // default value (aka initial value)
});
