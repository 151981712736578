import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import OrderFormModal from './OrderFormModal'; // OrderFormModal 컴포넌트 임포트
import { DateTime } from 'luxon';
import Calendar from 'react-calendar';
import Cookies from 'js-cookie';
import 'react-calendar/dist/Calendar.css';
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); // 4열 레이아웃
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f0f0f0;

  // 768px 이하일 때 레이아웃 변경
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr; // 2열 레이아웃으로 변경
    grid-gap: 10px; // 간격 줄이기
  }
`;

const Column = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 15px;

  // 768px 이하일 때 열 확장 설정
  @media (max-width: 768px) {
    &:nth-child(1), &:nth-child(2) {
      grid-column: span 1; // 각각 하나의 열 차지
    }

    &:nth-child(3), &:nth-child(4) {
      grid-column: span 2; // 두 번째 행 전체를 차지
    }
  }

  @media (max-width: 425px) {
    grid-column: span 2; // 모든 열이 전체를 차지
  }
`;

const Title = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const ButtonList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const TimeButton = styled.button`
  padding: 8px 12px;
  background-color: ${(props) => (props.selected ? '#e0e0e0' : 'white')};
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Input = styled.input`
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const Button = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background 0.3s;
  &:hover {
    background: #0056b3;
  }
`;

const SubTitle = styled.h3`
  font-size: 1rem;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
`;


// 스타일 컴포넌트 추가
const SelectWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

const LoadingSpinner = styled.div`
  position: absolute;
  right: 25px;
  top: 40%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: translateY(-50%) rotate(0deg); }
    100% { transform: translateY(-50%) rotate(360deg); }
  }
`;
const MovieScheduleFilter = ({ applyCurrentCoupon,couponPrice, setShowScheduleFilter, clearCouponCookie }) => {
  const [movies, setMovies] = useState([]);
  const [regions, setRegions] = useState([]);
  const [subRegions, setSubRegions] = useState([]);
  const [theaters, setTheaters] = useState([]);
  const [dates, setDates] = useState([]);
  const [times, setTimes] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentDate, setCurrentDate] = useState(DateTime.now());
  const [selectedDate, setSelectedDate] = useState(null);
  const [viewerCount, setViewerCount] = useState(1);
  const [amount, setAmount] = useState(0);
  //const couponPrices = JSON.parse(Cookies.get('couponPrices') || '{}');
  const [couponPrices, setCouponPrices] = useState(JSON.parse(Cookies.get('couponPrices') || '{}'));
  // 상단에 state 추가
//const [isLoading, setIsLoading] = useState(false);
// 개별 로딩 상태 추가
const [loadingStates, setLoadingStates] = useState({
  movies: false,
  regions: false,
  subRegions: false,
  theaters: false,
  dates: false,
  times: false
});

  const [filters, setFilters] = useState({
    cinemaBrand: '',
    movieTitle: '',
    region: '',
    subRegion: '',
    theater: '',
    date: '',
    time: '',
    menu_id: '', // menu_id를 추가합니다.
  });
  useEffect(() => {
    if (filters.time && Object.keys(couponPrices).length > 0) {
      const newAmount = couponPrices[`${viewerCount}인`] || 0;
      setAmount(newAmount);
    }
  }, [viewerCount, couponPrices, filters.time]);
  // 초기 영화 데이터 불러오기
  useEffect(() => {
    if (filters.cinemaBrand) {
      fetchMovies();
    }
  }, [filters.cinemaBrand]);

  // 필터가 변경될 때마다 관련 데이터를 불러오기
 // 초기 영화 데이터 불러오기
 useEffect(() => {
  if (filters.cinemaBrand) {
    fetchMovies();
  }
}, [filters.cinemaBrand]);

// 필터가 변경될 때마다 관련 데이터를 불러오기
useEffect(() => {
  if (filters.movieTitle) {
    fetchRegions();
  }
}, [filters.movieTitle]);

useEffect(() => {
  if (filters.region) {
    fetchSubRegions();
  }
}, [filters.region]);

useEffect(() => {
  if (filters.subRegion) {
    fetchTheaters();
  }
}, [filters.subRegion]);

useEffect(() => {
  if (filters.theater) {
    fetchDates();
  }
}, [filters.theater]);

useEffect(() => {
  if (filters.date) {
    fetchTimes();
  }
}, [filters.date]);

  // menu_id가 설정되었을 때 스케줄 데이터 불러오기
  useEffect(() => {
    if (filters.menu_id) {
      fetchSchedule();
    }
  }, [filters.menu_id]);

  // 현재 날짜 업데이트
  useEffect(() => {
    setCurrentDate(DateTime.now());
  }, []);
  // 영화 데이터 불러오기 (자식 데이터 개수 포함)
  const fetchMovies = async () => {
    try {
      setLoadingStates(prev => ({ ...prev, movies: true }));
      const response = await fetch('/movieschedules/movies');
      if (!response.ok) throw new Error('영화 데이터를 불러오는 데 실패했습니다.');
      const data = await response.json();

      // 오늘 날짜 가져오기
      const today = DateTime.now().toISODate();

      // 각 영화에 대해 지역 데이터를 가져와서 개수 확인
      const moviesWithCounts = await Promise.all(
        data.map(async (movie) => {
          const regionsResponse = await fetch(
            `/movieschedules/regions?movieTitle=${encodeURIComponent(
              movie
            )}&cinemaBrand=${filters.cinemaBrand}`
          );
          if (!regionsResponse.ok) throw new Error('지역 데이터를 불러오는 데 실패했습니다.');
          const regionsData = await regionsResponse.json();


          // 영화의 상영 날짜를 가져오기
          const datesResponse = await fetch(
            `/movieschedules/dates?movieTitle=${encodeURIComponent(
              movie
            )}&cinemaBrand=${filters.cinemaBrand}`
          );
          if (!datesResponse.ok) throw new Error('날짜 데이터를 불러오는 데 실패했습니다.');
          const datesData = await datesResponse.json();

          // 오늘 날짜 이상인 날짜만 남기기
          const validDates = datesData.filter((date) => date >= today);

          return { movieTitle: movie, count: regionsData.length, validDates };
        })
      );

      // 자식 데이터가 있는 영화만 필터링
      const filteredMovies = moviesWithCounts.filter((movie) => movie.count > 0);
      setMovies(filteredMovies);
    } catch (error) {
      console.error('영화 데이터 가져오기 오류:', error);
    } finally {
      setLoadingStates(prev => ({ ...prev, movies: false }));
    }
  };

  // 지역 데이터 불러오기 (자식 데이터 개수 포함)
  const fetchRegions = async () => {
    try {
      setLoadingStates(prev => ({ ...prev, regions: true }));
      const response = await fetch(
        `/movieschedules/regions?movieTitle=${filters.movieTitle}&cinemaBrand=${filters.cinemaBrand}`
      );
      if (!response.ok) throw new Error('지역 데이터를 불러오는 데 실패했습니다.');
      const data = await response.json();

      // 각 지역에 대해 세부 지역 데이터 가져와서 개수 확인
      const regionsWithCounts = await Promise.all(
        data.map(async (region) => {
          const subRegionsResponse = await fetch(
            `/movieschedules/sub-regions?movieTitle=${filters.movieTitle}&region=${encodeURIComponent(
              region
            )}&cinemaBrand=${filters.cinemaBrand}`
          );
          if (!subRegionsResponse.ok) throw new Error('세부 지역 데이터를 불러오는 데 실패했습니다.');
          const subRegionsData = await subRegionsResponse.json();
          return { region: region, count: subRegionsData.length };
        })
      );

      // 자식 데이터가 있는 지역만 필터링
      const filteredRegions = regionsWithCounts.filter((region) => region.count > 0);
      setRegions(filteredRegions);
    } catch (error) {
      console.error('지역 데이터 가져오기 오류:', error);
    } finally {
      setLoadingStates(prev => ({ ...prev, regions: false }));
    }
  };

  // 세부 지역 데이터 불러오기 (자식 데이터 개수 포함)
  const fetchSubRegions = async () => {
    try {
      setLoadingStates(prev => ({ ...prev, subRegions: true }));
      const response = await fetch(
        `/movieschedules/sub-regions?movieTitle=${filters.movieTitle}&region=${filters.region}&cinemaBrand=${filters.cinemaBrand}`
      );
      if (!response.ok) throw new Error('세부 지역 데이터를 불러오는 데 실패했습니다.');
      const data = await response.json();

      // 각 세부 지역에 대해 상영관 데이터 가져와서 개수 확인
      const subRegionsWithCounts = await Promise.all(
        data.map(async (subRegion) => {
          const theatersResponse = await fetch(
            `/movieschedules/theaters?movieTitle=${filters.movieTitle}&region=${filters.region}&subRegion=${encodeURIComponent(
              subRegion
            )}&cinemaBrand=${filters.cinemaBrand}`
          );
          if (!theatersResponse.ok) throw new Error('상영관 데이터를 불러오는 데 실패했습니다.');
          const theatersData = await theatersResponse.json();
          return { subRegion: subRegion, count: theatersData.length };
        })
      );

      // 자식 데이터가 있는 세부 지역만 필터링
      const filteredSubRegions = subRegionsWithCounts.filter((subRegion) => subRegion.count > 0);
      setSubRegions(filteredSubRegions);
    } catch (error) {
      console.error('세부 지역 데이터 가져오기 오류:', error);
    } finally {
      setLoadingStates(prev => ({ ...prev, subRegions: false }));
    }
  };

  // 상영관 데이터 불러오기 (자식 데이터 개수 포함)
  const fetchTheaters = async () => {
    try {
      setLoadingStates(prev => ({ ...prev, theaters: true }));
      const response = await fetch(
        `/movieschedules/theaters?movieTitle=${filters.movieTitle}&region=${filters.region}&subRegion=${filters.subRegion}&cinemaBrand=${filters.cinemaBrand}`
      );
      if (!response.ok) throw new Error('상영관 데이터를 불러오는 데 실패했습니다.');
      const data = await response.json();
  
      // 각 상영관에 대해 유효한 날짜 데이터만 카운트
      const theatersWithCounts = await Promise.all(
        data.map(async (theater) => {
          const datesResponse = await fetch(
            `/movieschedules/dates?movieTitle=${filters.movieTitle}&region=${filters.region}&subRegion=${filters.subRegion}&theater=${encodeURIComponent(theater)}`
          );
          if (!datesResponse.ok) throw new Error('날짜 데이터를 불러오는 데 실패했습니다.');
          const datesData = await datesResponse.json();
  
          // 현재 날짜 이후의 날짜만 필터링
          // const now = DateTime.now().setZone('Asia/Seoul');
          // const today = now.toISODate();
          
          // const validDates = datesData
          //   .map(date => date.split('T')[0])
          //   .filter(date => date >= today);
  
          return { theater: theater, count: datesData.length };
        })
      );
  
      const filteredTheaters = theatersWithCounts.filter((theater) => theater.count > 0);
      setTheaters(filteredTheaters);
    } catch (error) {
      console.error('상영관 데이터 가져오기 오류:', error);
    } finally {
      setLoadingStates(prev => ({ ...prev, theaters: false }));
    }
  };
 // 날짜 데이터 불러오기
 const fetchDates = async () => {
  try {
    const response = await fetch(
      `/movieschedules/dates?movieTitle=${filters.movieTitle}&region=${filters.region}&subRegion=${filters.subRegion}&theater=${filters.theater}`
    );
    if (!response.ok) throw new Error('날짜 데이터를 불러오는 데 실패했습니다.');
    const data = await response.json();

    const now = DateTime.now().setZone('Asia/Seoul');
    const today = now.toISODate(); // 오늘 날짜 (ISO 형식)
    
    // 오늘 날짜 이후의 날짜만 필터링
    const formattedDates = data
      .map((item) => item.split('T')[0])
      .filter((date) => date >= today);  // 단순히 오늘 날짜 이후만 체크

    setDates(formattedDates);
  } catch (error) {
    console.error('날짜 데이터 가져오기 오류:', error);
  }
};

  // 시간 데이터 불러오기
  const fetchTimes = async () => {
    try {
      const formattedDate = filters.date;
      const response = await fetch(
        `/movieschedules/times?movieTitle=${filters.movieTitle}&region=${filters.region}&subRegion=${filters.subRegion}&theater=${filters.theater}&date=${formattedDate}`
      );
      if (!response.ok) throw new Error('시간 데이터를 불러오는 데 실패했습니다.');
      const data = await response.json();
  
      // 오늘 날짜인 경우 현재 시간 이후의 시간만 필터링
      const today = DateTime.now().setZone('Asia/Seoul').toISODate();
      if (formattedDate === today) {
        const currentTime = DateTime.now().setZone('Asia/Seoul').toFormat('HH:mm');  // HH:mm 형식으로 변경
        console.log('현재시간:', currentTime);  // 디버깅용
        const filteredTimes = data.filter(time => {
          console.log('비교시간:', time.screening_time);  // 디버깅용
          return time.screening_time > currentTime;
        });
        console.log('필터링된 시간들:', filteredTimes);  // 디버깅용
        // setTimes(data);
        setTimes(filteredTimes);
      } else {
        setTimes(data);
      }
    } catch (error) {
      console.error('시간 데이터 가져오기 오류:', error);
    }
  };
  // 예매하기 버튼 클릭 핸들러
  const handleProceedToOrder = async () => {
    try {
      const response = await fetch(`/movieschedules/schedule/${filters.menu_id}`);
      if (!response.ok) throw new Error('일정 데이터를 불러오는 데 실패했습니다.');
      const data = await response.json();
      setSelectedSchedule(data);
      setShowModal(true);
    } catch (error) {
      console.error('일정 데이터 가져오기 오류:', error);
    }
  };
  // 선택된 시간에 대한 상영 일정 데이터 불러오기
  const fetchSchedule = async () => {
    try {
      const response = await fetch(`/movieschedules/schedule/${filters.menu_id}`);
      if (!response.ok) throw new Error('일정 데이터를 불러오는 데 실패했습니다.');
      const data = await response.json();
      //setShowModal(true); // 일정 선택 시 모달 표시
    } catch (error) {
      console.error('일정 데이터 가져오기 오류:', error);
    }
  };

  // 필터 변경 핸들러
  const handleChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
      ...(name === 'cinemaBrand' && {
        movieTitle: '',
        region: '',
        subRegion: '',
        theater: '',
        date: '',
        time: '',
        menu_id: '',
      }),
      ...(name === 'movieTitle' && {
        region: '',
        subRegion: '',
        theater: '',
        date: '',
        time: '',
        menu_id: '',
      }),
      ...(name === 'region' && { subRegion: '', theater: '', date: '', time: '', menu_id: '' }),
      ...(name === 'subRegion' && { theater: '', date: '', time: '', menu_id: '' }),
      ...(name === 'theater' && { date: '', time: '', menu_id: '' }),
      ...(name === 'date' && { time: '', menu_id: '' }),
    }));
  };

  // 시간 선택 핸들러
  const handleTimeSelect = (menu_id, screening_time) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      time: screening_time,
      menu_id: menu_id, // 선택한 menu_id를 저장합니다.
    }));
  };
  const handleViewerCountChange = (e) => {
    const count = Number(e.target.value);
    setViewerCount(count);
    const newAmount = couponPrices[`${count}인`] || 0;
    setAmount(newAmount);
  };
  // 시간 형식 변환 함수 (HH:MM)
  const formatTime = (time) => {
    const hours = time.slice(0, 2);
    const minutes = time.slice(2, 4);
    return `${hours}:${minutes}`;
  };

  // 캘린더 렌더링 함수
  const renderCalendar = () => {
    const today = DateTime.now();

    return (
      <Calendar
        onChange={(date) => handleChange('date', DateTime.fromJSDate(date).toISODate())}
        value={filters.date ? new Date(filters.date) : new Date()}
        minDate={today.minus({ months: 3 }).toJSDate()}
        tileDisabled={({ date }) => !dates.includes(DateTime.fromJSDate(date).toISODate())}
        tileContent={({ date, view }) =>
          view === 'month' ? (
            <div style={{ textAlign: 'center', fontSize: '14px', lineHeight: '1.5em' }}>
              {DateTime.fromJSDate(date).toFormat('d')}
            </div>
          ) : null
        }
        formatDay={(locale, date) => ''}
      />
    );
  };

  return (
    <>
      <Container>

        <Column>
          <Title>영화선택</Title>
          <Select
            name="cinemaBrand"
            value={filters.cinemaBrand}
            onChange={(e) => handleChange('cinemaBrand', e.target.value)}
          >
            <option value="">영화관 선택</option>
            <option value="CGV">CGV</option>
            <option value="메가박스">메가박스</option>
            <option value="롯데시네마">롯데시네마</option>
          </Select>
          
      {filters.cinemaBrand && (
        <SelectWrapper>
                
          <Select
            name="movieTitle"
            value={filters.movieTitle}
            onChange={(e) => handleChange('movieTitle', e.target.value)}
            disabled={loadingStates.movies}
          >
            <option value="">영화 선택</option>
            {movies.map((movie, index) => (
              <option key={index} value={movie.movieTitle}>
                {movie.movieTitle} ({movie.count})
              </option>
            ))}
          </Select>
          {loadingStates.movies && <LoadingSpinner />}
        </SelectWrapper>
      )}
    </Column>
        {filters.movieTitle && (
          <Column>
       
          <SelectWrapper>
            <Select
              name="region"
              value={filters.region}
              onChange={(e) => handleChange('region', e.target.value)}
              disabled={loadingStates.regions}
            >
              <option value="">지역 선택</option>
              {regions.map((region, index) => (
                <option key={index} value={region.region}>
                  {region.region} ({region.count})
                </option>
              ))}
            </Select>
            {loadingStates.regions && <LoadingSpinner />}
          </SelectWrapper>
            {filters.region && (
            <SelectWrapper>
            <Select
              name="subRegion"
              value={filters.subRegion}
              onChange={(e) => handleChange('subRegion', e.target.value)}
              disabled={loadingStates.subRegions}
            >
              <option value="">세부지역 선택</option>
              {subRegions.map((subRegion, index) => (
                <option key={index} value={subRegion.subRegion}>
                  {subRegion.subRegion} ({subRegion.count})
                </option>
              ))}
            </Select>
            {loadingStates.subRegions && <LoadingSpinner />}
          </SelectWrapper>
            )}
            {filters.subRegion && (
       <SelectWrapper>
       <Select
         name="theater"
         value={filters.theater}
         onChange={(e) => handleChange('theater', e.target.value)}
         disabled={loadingStates.theaters}
       >
         <option value="">상영관 선택</option>
         {theaters.map((theater, index) => (
           <option key={index} value={theater.theater}>
             {theater.theater} ({theater.count})
           </option>
         ))}
       </Select>
       {loadingStates.theaters && <LoadingSpinner />}
     </SelectWrapper>
            )}
          </Column>
        )}
         {filters.theater && (
          <Column>
            <Title>일정선택</Title>
            {renderCalendar()}
            {filters.date && (
              <>
                <Title>시간선택</Title>
                <ButtonList>
                  {times.map((time, index) => (
                    <TimeButton
                      key={index}
                      selected={filters.time === time.screening_time}
                      onClick={() => handleTimeSelect(time.menu_id, time.screening_time)}
                    >
                      {time.screening_time}
                    </TimeButton>
                  ))}
                </ButtonList>
              </>
            )}
          </Column>
        )}
        {filters.time && (
          <Column>
          {/* applyCurrentCoupon */}
            <Title>관람 인원 및 금액</Title>
            <Select id="viewerCount" value={viewerCount} onChange={handleViewerCountChange}>
              {[1, 2, 3, 4, 5, 6].map((count) => (
                count >= applyCurrentCoupon.min_order_cnt && count <= applyCurrentCoupon.max_order_cnt ?
                <option key={count} value={count}>{count}명</option> : <></>
              ))}
            </Select>
          예매인원 제한 {applyCurrentCoupon.min_order_cnt} ~ {applyCurrentCoupon.max_order_cnt}
            {/* 아무래도 이값 아니고 최대 인원수를 쿠폰에 정해놔야할듯함 */}
            <Title>금액</Title>
            <Input type="number" value={amount} disabled />
            <Button onClick={handleProceedToOrder}>예매하기</Button>
          </Column>
        )}
      </Container>
      {showModal && selectedSchedule && (
        <OrderFormModal
          onClose={() => {
            clearCouponCookie();
            setShowScheduleFilter(false);
            setShowModal(false);
          }}
          movieInfo={{
            ...selectedSchedule,
            screeningRoom: `${selectedSchedule.theater} ${selectedSchedule.screening_place}`,
            startTime: filters.time,
            cinemaBrand: filters.cinemaBrand,
          }}
          movieViewerCount={viewerCount}
          totalAmount={amount}
        />
      )}
    </>
  );
};

export default MovieScheduleFilter;