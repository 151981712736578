import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import SubNavigator from "../../components/SubNavigator";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CallIcon from "@mui/icons-material/Call";
import Tooltip from "@mui/material/Tooltip"; // Import Tooltip component

import AnnouncementIcon from "@mui/icons-material/Announcement";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

import { useAuth } from "../../AuthContext";
import { getSessionFunc } from "../../api/func/userFunc";

const links = [
  { path: "/cscenter/board/1", label: "공지사항", icon: AnnouncementIcon },
  {
    path: "/cscenter/board/guide",
    label: "영화예매가이드",
    icon: LocalMoviesIcon,
  },
  { path: "/cscenter/board/2", label: "자주묻는질문", icon: LiveHelpIcon },
  { path: "/cscenter/board/3", label: "구매문의", icon: ShoppingCartIcon },
  { path: "/cscenter/board/17", label: "1대1문의", icon: SupportAgentIcon },
];

const GlobalStyle = createGlobalStyle`
  * {
    -webkit-tap-highlight-color: transparent; /* Disable tap highlight color on mobile devices */
  }

  button {
    outline: none; /* Remove default focus outline */
    -webkit-user-select: none; /* Disable text selection */
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const CSCenterWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 80px); /* Adjusted to remove top navigation height */

  @media (max-width: 580px) {
    flex-direction: column; /* Make the layout vertical on smaller screens */
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  min-width: 10rem; /* Ensure the navigation has a consistent width */
  box-sizing: border-box; /* Include padding and border in the width */

  @media (max-width: 580px) {
    display: none; /* Hide the navigation on smaller screens */
  }
`;

const Main = styled.main`
  background-color: white;
  flex: 1;
  padding: 10px; /* Ensure the content is not touching the edges */
  margin: 20px; /* Reset margin to avoid additional spacing */
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    margin-top: 0;
    padding-top: 0;
  }
`;

const Title = styled.div`
  font-weight: bold;
  background-color: #ffc107;
  color: #1b1f23;
  font-size: 1.1em; /* 제목 크기, 필요에 따라 조정 */
  text-align: left;
  padding: 10px;
  border-top: 5px solid #dd9d22; /* 상단 테두리 색상 및 두께 */
  width: 10rem; /* Set the same width as Nav to match */
  box-sizing: border-box; /* Include padding and border in the width */
  margin-top: 30px; /* Add top margin to create space above the title */

  @media (max-width: 768px) {
    margin-top: 0;
  }

  @media (max-width: 580px) {
    width: 3rem; /* Adjust width for mobile view */
  }
`;

const pressAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

const MobileNavButton = styled.button`
  display: none;

  @media (max-width: 580px) {
    display: flex; /* Change to flex to center the icon */
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: #ffc107;
    color: #1b1f23;
    border: none;
    border-radius: 50%; /* Make it circular */
    font-weight: bold;
    cursor: pointer;
    z-index: 1000; /* Ensure the button is above other elements */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.1s ease;
    outline: none; /* Remove the focus outline */
    user-select: none; /* Prevent text selection */

    &:active {
      animation: ${pressAnimation} 0.2s;
    }

    &:focus {
      outline: none; /* Remove the focus outline */
    }
  }
`;

const ModalContent = styled.div`
 display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
 position: fixed;
 bottom: 70px;
 left: 20px;
 background-color: white;
 padding: 10px;
 border-radius: 5px;
 box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 z-index: 1001;
`;

const ExpandableButtons = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 80px;
  left: 20px;
  z-index: 1000;
`;

const ExpandedButton = styled.button`
  width: 50px;
  height: 50px;
  margin-top: 10px;
  background-color: #ffc107;
  color: #1b1f23;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  outline: none; /* Remove the focus outline */
  user-select: none; /* Prevent text selection */

  &:active {
    animation: ${pressAnimation} 0.2s;
  }

  &:focus {
    outline: none; /* Remove the focus outline */
  }
`;

export default function CSCenter() {
  const { isLoggedIn, setIsLoggedIn, setUser } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const wrapperRef = useRef(null);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const toggleExpand = () => {
    setExpanded(!isExpanded);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        if (!isMobileDevice() && window.innerWidth <= 580) {
          alert("클립보드에 저장되었습니다.");
        }
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleButtonClick = (action) => {
    action();
    setExpanded(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 580) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, [wrapperRef]);

  useEffect(() => {
    // console.log("HP");
    const storedUser = JSON.parse(sessionStorage.getItem("user"));

    // id로 session 데이터 가져옴
    const getSession = async (phone_number) => {
      const returnSession = await getSessionFunc(phone_number);
      return returnSession;
    };

    //로그인 상태 판별
    const isLoginFunc = async (storedUser) => {
      // console.log(`=== start is login ===`);
      // 세션 스토리지의 id를 가져온다.
      if (
        storedUser !== null &&
        (storedUser.phone_number !== null ||
          storedUser.phone_number !== undefined)
      ) {
        try {
          // console.log("session 값이 있음");
          // console.log(`storedUser.phone_number : ${storedUser.phone_number}`);
          const returnSession = await getSession(storedUser.phone_number);
          // console.log(returnSession);
          // console.log(JSON.stringify(returnSession));
          // console.log(`code : ${returnSession.code} `);
          if (returnSession.code == 200 && returnSession.result !== 0) {
            // 서버 세션이 남아있을 경우
            // console.log("서버 session true");
            setIsLoggedIn(true);
            setUser(storedUser);
          } else {
            // 서버 Redis에 세션이 없을 경우
            // console.log("서버 session false");
            setIsLoggedIn(false);
            setUser(null);
            sessionStorage.removeItem("user");
          }
        } catch (e) {
          console.log("로그인 판별 에러 : e");
        }
      } else {
        // 세션 스토리지 아이디가 없을 경우
        // console.log("session false");
        setIsLoggedIn(false);
        setUser(null);
      }
      // console.log(`=== end is login ===`);
    };
    isLoginFunc(storedUser);
  }, [isLoggedIn]);

  return (
    <>
      <GlobalStyle />
      <CSCenterWrapper>
        <Nav>
          <Title>고객센터</Title>
          <SubNavigator links={links} />
        </Nav>
        <Main>
          <Outlet />
        </Main>

        <div ref={wrapperRef}>
          <MobileNavButton onClick={toggleExpand}>
            <ListAltIcon fontSize="large" /> {/* Use ListAltIcon here */}
          </MobileNavButton>

          {isExpanded && (
            <ExpandableButtons>
              <Tooltip title="공지사항" placement="top" arrow>
                <ExpandedButton
                  onClick={() =>
                    handleButtonClick(() => navigate("/cscenter/board/1"))
                  }
                >
                  <AnnouncementIcon />
                </ExpandedButton>
              </Tooltip>
              <Tooltip title="영화예매가이드" placement="top" arrow>
                <ExpandedButton
                  onClick={() =>
                    handleButtonClick(() => navigate("/cscenter/board/guide"))
                  }
                >
                  <LocalMoviesIcon />
                </ExpandedButton>
              </Tooltip>
              <Tooltip title="자주묻는질문" placement="top" arrow>
                <ExpandedButton
                  onClick={() =>
                    handleButtonClick(() => navigate("/cscenter/board/2"))
                  }
                >
                  <LiveHelpIcon />
                </ExpandedButton>
              </Tooltip>
              <Tooltip title="구매문의" placement="top" arrow>
                <ExpandedButton
                  onClick={() =>
                    handleButtonClick(() => navigate("/cscenter/board/3"))
                  }
                >
                  <ShoppingCartIcon />
                </ExpandedButton>
              </Tooltip>
              <Tooltip title="1대1문의" placement="top" arrow>
                <ExpandedButton
                  onClick={() =>
                    handleButtonClick(() => navigate("/cscenter/board/17"))
                  }
                >
                  <SupportAgentIcon />
                </ExpandedButton>
              </Tooltip>
              <Tooltip title="고객센터" placement="top" arrow>
                <ExpandedButton
                  onClick={() =>
                    handleButtonClick(() => handleCopyToClipboard("1522-0391"))
                  }
                >
                  <CallIcon />
                </ExpandedButton>
              </Tooltip>
            </ExpandableButtons>
          )}
        </div>

        <ModalContent $isOpen={isModalOpen}>
          <SubNavigator links={links} onLinkClick={closeModal} />
        </ModalContent>
      </CSCenterWrapper>
    </>
  );
}
