import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import Cookies from 'js-cookie';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
const OrderFormModal = ({ onClose, movieInfo,setShowScheduleFilter,totalAmount,movieViewerCount }) => {
  const navigate = useNavigate();
  const [inputMode, setInputMode] = useState('selectedSeat');
  const [amount, setAmount] = useState(totalAmount);
  const [viewerCount, setViewerCount] = useState(movieViewerCount);
  const [selectedSeat, setSelectedSeat] = useState('');
  const [seatText, setSeatText] = useState('');
  const [selectedSeatValue, setSelectedSeatsValue] = useState('');
  const [highlightedSeat, setHighlightedSeat] = useState(''); // 하나의 좌석만 선택할 수 있도록 변경
  //const [highlightedSeats, setHighlightedSeats] = useState([]);
  const couponPrices = JSON.parse(Cookies.get('couponPrices') || '{}');

  useEffect(() => {
    fetch('/request/session')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch session data');
        }
        return response.json();
      })
      .then(data => {
        if (data.userName) {
          sessionStorage.setItem('name', data.userName);
        }
        if (data.userId) {
          sessionStorage.setItem('phone_number', data.userId);
        }

        const name = sessionStorage.getItem('name');
        const phone_number = sessionStorage.getItem('phone_number');

        console.log('Name:', name);
        console.log('Phone Number:', phone_number);

        const couponCode = Cookies.get('couponCode');
        console.log('Coupon Code:', couponCode);

        // 관람 인원에 맞는 쿠폰 가격 설정
        const initialAmount = couponPrices[`${viewerCount}인`] || 0;
        setAmount(initialAmount);
      })
      .catch(error => {
        console.error('Error fetching session data:', error);
      });
  }, [viewerCount, couponPrices]);

  // const handleSeatClick = (section) => {
  //   let updatedSeats = highlightedSeats.includes(section)
  //     ? highlightedSeats.filter(seat => seat !== section)
  //     : [...highlightedSeats, section];
  //   setHighlightedSeats(updatedSeats);
  //   setSelectedSeats(updatedSeats.join(', '));
  //   setSelectedSeatsValue(updatedSeats.join(', '));
  // };
  const handleSeatClick = (section) => {
    if (inputMode === 'selectedSeat') {
      setHighlightedSeat(section);
      setSelectedSeat(section);
      setSelectedSeatsValue(section);
    }
  };

  // const handleManualSeatChange = (e) => {
  //   setSelectedSeat(e.target.value);
  //   setHighlightedSeat(e.target.value.split(',').map(seat => seat.trim()));
  // };

  // const handleViewerCountChange = (e) => {
  //   const count = Number(e.target.value);
  //   setViewerCount(count);
  //   const newAmount = couponPrices[`${count}인`] || 0;
  //   setAmount(newAmount);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedDate = DateTime.fromISO(movieInfo.date).toISODate();
  
    const formData = {
      movieTitle: movieInfo.movie_title,
      theater: movieInfo.theater,
      cinemaBrand: movieInfo.cinemaBrand,
      date: formattedDate,
      screeningTime: movieInfo.startTime,
      viewerCount,
      seatInfo: transformSeatInfoInString(selectedSeat) + seatText,
      seatInfoValue:selectedSeatValue,
      amount,
      bookingUrlPc: movieInfo.booking_url_pc,
      bookingUrlMobile: movieInfo.booking_url_mobile,
      orderCategory: '씨네트리',
      couponCode: Cookies.get('couponCode'),
      userName: sessionStorage.getItem('name'),
      phoneNumber: sessionStorage.getItem('phone_number'),
      couponPrice: amount,
      paymentMethod: '무통장입금',
      paymentStatus: 'pending',
      orderStatus: '예매대기'
    };
  
    try {
      // 2. 주문 제출 요청
      const response = await fetch('/order/submit-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        // alert(responseData.message + ' 주문번호: ' + responseData.orderId);
        alert(responseData.message);
        // 쿠폰 관련 쿠키 제거
        Cookies.remove('couponCode', { path: '/' });
        Cookies.remove('couponPrices', { path: '/' });
        navigate(`/mypage/order/3`);
        onClose(); // 모달 닫기
      } else {
        // 백엔드에서 보낸 에러 메시지를 받아옵니다.
        const errorData = await response.json();
        // 에러 메시지를 사용자에게 표시합니다.
        alert(':' + errorData.error);
      }
    } catch (error) {
      console.error(':', error);
      alert(':' + error.message);
    }
  };
  const seatMapping = {
    "front center": "앞 중앙 좌석",
    "front left": "앞 좌측 좌석",
    "front right": "앞 우측 좌석",
    "middle center": "중간 중앙 좌석",
    "middle left": "중간 좌측 좌석",
    "middle right": "중간 오른쪽 좌석",
    "back center": "뒤 중앙 좌석",
    "back left": "뒤 좌측 좌석",
    "back right": "뒤 우측 좌석"
  };
  
  function transformSeatInfoInString(text) {
    let transformedText = text;
    
    // seatMapping의 각 키값을 찾아서 해당 값을 변환
    for (const [key, value] of Object.entries(seatMapping)) {
      const regex = new RegExp(key, 'g'); // 문자열에서 여러 개 일치할 수 있으므로 정규식 사용
      transformedText = transformedText.replace(regex, value);
    }
    
    return transformedText;
  }

  return (
    <ModalOverlay>
      <ModalContent>
        <FormTitle>예매 정보 입력</FormTitle>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>영화 제목</Label>
            <Input type="text" value={movieInfo.movie_title} disabled />
          </FormGroup>
          <FormGroup>
            <Label>상영 일자</Label>
            <Input type="text" value={DateTime.fromISO(movieInfo.date).toFormat('yyyy-MM-dd')} disabled />
          </FormGroup>
          <FormGroup>
            <Label>상영 시간</Label>
            <Input type="text" value={movieInfo.startTime} disabled />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="screeningRoom">상영관</Label>
            <Input id="screeningRoom" type="text" value={`${movieInfo.theater} ${movieInfo.screening_place}`} disabled />
          </FormGroup>
          {/* <FormGroup>
            <Label htmlFor="amount">금액</Label>
            <Input 
              id="amount" 
              type="number" 
              placeholder="금액 입력" 
              value={amount} 
              onChange={(e) => setAmount(Number(e.target.value))} 
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="viewerCount">관람 인원</Label>
            <Select disabled id="viewerCount" value={viewerCount} onChange={handleViewerCountChange}>
              {[1, 2, 3, 4, 5, 6].map((count) => (
                <option key={count} value={count}>{count}명</option>
              ))}
            </Select>
          </FormGroup> */}
          <FormGroup>
            <ScreenContainer>
              <Screen>스크린</Screen>
              <SeatsGrid>
  <SeatBox
    onClick={() => handleSeatClick('front left')}
    selected={highlightedSeat === 'front left'} // 선택된 좌석과 비교
  >
    {highlightedSeat === 'front left' ? '앞 왼쪽' : ''}
  </SeatBox>
  <SeatBox
    onClick={() => handleSeatClick('front center')}
    selected={highlightedSeat === 'front center'}
  >
    {highlightedSeat === 'front center' ? '앞 중앙' : ''}
  </SeatBox>
  <SeatBox
    onClick={() => handleSeatClick('front right')}
    selected={highlightedSeat === 'front right'}
  >
    {highlightedSeat === 'front right' ? '앞 오른쪽' : ''}
  </SeatBox>
  <SeatBox
    onClick={() => handleSeatClick('middle left')}
    selected={highlightedSeat === 'middle left'}
  >
    {highlightedSeat === 'middle left' ? '중간 왼쪽' : ''}
  </SeatBox>
  <SeatBox
    onClick={() => handleSeatClick('middle center')}
    selected={highlightedSeat === 'middle center'}
  >
    {highlightedSeat === 'middle center' ? '중간 중앙' : ''}
  </SeatBox>
  <SeatBox
    onClick={() => handleSeatClick('middle right')}
    selected={highlightedSeat === 'middle right'}
  >
    {highlightedSeat === 'middle right' ? '중간 오른쪽' : ''}
  </SeatBox>
  <SeatBox
    onClick={() => handleSeatClick('back left')}
    selected={highlightedSeat === 'back left'}
  >
    {highlightedSeat === 'back left' ? '뒤 왼쪽' : ''}
  </SeatBox>
  <SeatBox
    onClick={() => handleSeatClick('back center')}
    selected={highlightedSeat === 'back center'}
  >
    {highlightedSeat === 'back center' ? '뒤 중앙' : ''}
  </SeatBox>
  <SeatBox
    onClick={() => handleSeatClick('back right')}
    selected={highlightedSeat === 'back right'}
  >
    {highlightedSeat === 'back right' ? '뒤 오른쪽' : ''}
  </SeatBox>
  </SeatsGrid>
            </ScreenContainer>
        </FormGroup>
  <div style={{ display:'flex',alignItems:'center',flexDirection: 'column'}}>
  <FormGroup>
  <RadioContainer>
    <Label>
      <input
        type="radio"
        name="seatInputMode"
        value="selectedSeat"
        checked={inputMode === 'selectedSeat'}
        onChange={() => setInputMode('selectedSeat')}
      />
      선택 좌석
    </Label>
    <Input
      type="text"
      placeholder="좌석 정보"
      value={transformSeatInfoInString(selectedSeat)}
      disabled={inputMode !== 'selectedSeat'}
    />
  </RadioContainer>
</FormGroup>

<FormGroup>
  <RadioContainer>
    <Label>
      <input
        type="radio"
        name="seatInputMode"
        value="manual"
        checked={inputMode === 'manual'}
        onChange={() => setInputMode('manual')}
      />
      직접 입력
    </Label>
    <Input
      type="text"
      placeholder="좌석을 직접 입력하세요"
      value={seatText}
      onChange={(e) => setSeatText(e.target.value)}
      disabled={inputMode !== 'manual'}
    />
  </RadioContainer>
</FormGroup>
  </div>
          <ButtonGroup>
            <Button type="submit">예매 주문</Button>
            <Button type="button" onClick={onClose}>취소</Button>
          </ButtonGroup>
        </Form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default OrderFormModal;

// 스타일 컴포넌트들 정의

const ModalOverlay = styled.div`
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
`;

const FormTitle = styled.h2`
  margin-bottom: 1rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  margin-right: 10px;
  font-weight: bold;
`;

const Select = styled.select`
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const Input = styled.input`
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background 0.3s;
  &:hover {
    background: #0056b3;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const ScreenContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Screen = styled.div`
  background: linear-gradient(to bottom, #ccc, #999);
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 400px;
  text-align: center;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const SeatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  width: 90%;
  max-width: 300px;
`;

const SeatBox = styled.div`
  background: ${props => (props.selected ? '#007bff' : '#e0e0e0')};
  padding: 10px;
  text-align: center;
  font-size: 12px;
  border-radius: 4px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  transition: background 0.3s, transform 0.3s;
  box-shadow: ${props => (props.selected ? '0 4px 6px rgba(0, 123, 255, 0.2)' : '0 4px 6px rgba(0, 0, 0, 0.1)')};
  &:hover {
    background: ${props => (props.selected ? '#0056b3' : props.disabled ? '#e0e0e0' : '#c1c1c1')};
    transform: ${props => (props.disabled ? 'none' : 'scale(1.05)')};
  }
`;
const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  
  input[type="radio"] {
    margin-right: 0.5rem;
  }
  
  input[type="text"] {
    flex: 1;
  }
`;