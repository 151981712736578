import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const LotteWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: gray;
  width: 100%;
  height: 100%;
`;

const LotteImage = styled.img`
  margin-top: 50px;
  max-width: 100%;
  height: auto;
`;

export default function Lotte() {
  return (
    <LotteWrapper>
      <LotteImage src="/images/lotte_img.gif" alt="Lotte" />
      <Outlet />
    </LotteWrapper>
  );
}
