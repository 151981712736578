import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./screen/Home";
import Navigator from "./components/Navigator";
import { AuthProvider } from "./AuthContext";
import { HomeProvider } from "./screen/Home/HomeContext";
import CSCenter from "./screen/CSCenter";
import CSCenterNotice from "./screen/CSCenter/CSCenterNotice";
import CSCenterGuide from "./screen/CSCenter/CSCenterGuide";
import CSCenterFAQ from "./screen/CSCenter/CSCenterFAQ";
import CSCenterPurchase from "./screen/CSCenter/CSCenterPurchase";
import CSCenter1to1 from "./screen/CSCenter/CSCenter1to1";
import PostViewer from "./screen/PostViewer";
import WriteEditor from "./screen/WriteEditor";
import WriteEditorLite from "./screen/WriteEditorLite";
import UpdateEditer from "./screen/WriteEditor/Update";
import UpdateEditorLite from "./screen/WriteEditorLite/Update";
import CGV from "./screen/TheaterInfo/CGV";
import Lotte from "./screen/TheaterInfo/Lotte";
import Mega from "./screen/TheaterInfo/Mega";
import TheaterInfo from "./screen/TheaterInfo";
import MyPage from "./screen/MyPage";
import MyPageOrder from "./screen/MyPage/MyPageOrder";
import MyPageUser from "./screen/MyPage/MyPageUser";
import MyPageCoupon from "./screen/MyPage/MyPageCoupon";
import MyPageUserbank from "./screen/MyPage/MyPageUserbank";
import Footer, { Useinfo, Privacy, Kid } from "./components/Footer";
import StickerContainer from "./screen/Sticker";
import { useAuth } from "./AuthContext";

import Login from "./components/Login";
import ResetPassword from "./components/Login/ResetPassword";
import AutoLogin from "./screen/AutoLogin";
import PasswordResetForm from "./components/PasswordReset";
import RegisterForm from "./components/Register";
import CouponRegister from "./screen/Sticker/RegisterCoupon";
import MovieInfo from "./screen/MovieInfo";
import IngMovieInfo from "./screen/MovieInfo/IngMovieInfo";
import PrevMovieInfo from "./screen/MovieInfo/PrevMovieInfo";
import DetailsMovieInfo from "./screen/MovieInfo/DetailsMovieInfo";
import MovieTicketing from "./screen/MovieTicketing";
import ProductRecommendation, {
  ShippingInfo,
  OrderComplete,
  ProductDetailPage,
} from "./screen/Product";

import { PaymentSuccess } from "./screen/PgPayment/PaymentSuccess";
import { PaymentFail } from "./screen/PgPayment/PaymentFail";
import PgPayment from "./screen/PgPayment";
import TicketPurchase from "./screen/TicketPurchase";
import MaintenancePopup from "./components/MaintenancePopup";
import AppSetupConfig from "./AppSetupConfig";
import GlobalStyle from './GlobalStyle';
import { getSessionFunc } from "./api/func/userFunc";
import { PrivateRoute, PublicRoute } from "./components/Routes/PrivateRoute";
import GlobalPopup from "./components/GlobalPopup";
function App() {
  let navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, user, login, logout, setIsLoggedIn, setUser } = useAuth();
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [autoLoginCompleted, setAutoLoginCompleted] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupData, setPopupData] = useState({
    imgWidth: 0,
    imgHeight: 0,
    mImgWidth: 0,
    mImgHeight: 0,
    imageUrl: "",
  });
  const handleClosePopup = () => {
    setPopupVisible(false);
  };
  const isWithDateRange = (startDate, endDate) => {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    console.log('날짜 비교:');
    console.log('오늘:', today);
    console.log('시작일:', start);
    console.log('종료일:', end);
    console.log('시작일 체크:', today >= start);
    console.log('종료일 체크:', today <= end);
  
    return today >= start && today <= end;
  };

  useEffect(() => {
    const fetchPopupData = async () => {
      try {
        const response = await fetch("/control/getPopupPath");
        const data = await response.json();
        console.log('팝업 대상 확인'+JSON.stringify(data));
        console.log('경로:'+ data[1].target_path);
        console.log('대상경로:'+location.pathname);
        // 현재 경로와 일치하는 팝업 찾기
        const matchingPopup = data.find(popup => {
          console.log('비교 중인 팝업:', popup);
          console.log(`팝업 target_path: ${popup.target_path}`);
          console.log(`현재 pathname: ${location.pathname}`);
          console.log(`경로 일치 여부: ${popup.target_path === location.pathname}`);
          
          const dateInRange = isWithDateRange(popup.start_date, popup.end_date);
          console.log(`날짜 범위 체크: ${dateInRange}`);
          console.log('시작일:', popup.start_date);
          console.log('종료일:', popup.end_date);
          
          return popup.target_path === location.pathname && dateInRange;
        });
        
        console.log('최종 매칭된 팝업:', matchingPopup);
        console.log('매칭된 팝업:', matchingPopup);

      // 일치하는 팝업이 있으면 보여주기
      if (matchingPopup) {
        setPopupData({
          imgWidth: matchingPopup.img_width,
          imgHeight: matchingPopup.img_height,
          mImgWidth: matchingPopup.m_img_width,
          mImgHeight: matchingPopup.m_img_height,
          imageUrl: matchingPopup.upload,
        });
        setPopupVisible(true);
      }
    } catch (error) {
      console.error("Error fetching popup data:", error);
    }
  };
    fetchPopupData();
  }, [location.pathname]); // location.pathname이 변경될 때마다 실행
// 컴포넌트 마운트 시 자동 로그인 체크

  return (
    <>
    <GlobalStyle/>
    <AuthProvider>
    <AutoLoginCheck />
      <div className="App">
        <Navigator />
        <div className="content-wrapper">
          <main>
            <Routes>
              <Route
                path="/"
                element={
                  <HomeProvider>
                    <Home />
                  </HomeProvider>
                }
              />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route
                path="/autoLogin"
                element={<AutoLogin></AutoLogin>}
              ></Route>
              <Route
                path="/pay_success"
                element={<PaymentSuccess></PaymentSuccess>}
              />
              <Route path="/pay_fail" element={<PaymentFail></PaymentFail>} />
              <Route path="/pg_payment" element={<PgPayment />} />
              <Route path="/ticket_purchase" element={<TicketPurchase />} />
              <Route path="/user/user/login" element={<Login />} />
              <Route
                path="/user/user/finduserinfo"
                element={<PasswordResetForm />}
              />
              <Route path="/user/user/create" element={<RegisterForm />} />
              <Route path="/etc/useinfo" element={<Useinfo />} />
              <Route path="/etc/privacy" element={<Privacy />} />
              <Route path="/etc/kid" element={<Kid />} />

              <Route
                path="/movie/ticketing"
                element={
                  <HomeProvider>
                    <MovieTicketing />
                  </HomeProvider>
                }
                key="MovieTicketing"
              />
              <Route
                path="/movie/info/*"
                element={
                  <HomeProvider>
                    <MovieInfo />
                  </HomeProvider>
                }
                key="MovieInfo"
              >
                <Route
                  path="details/:movie_code"
                  element={<DetailsMovieInfo />}
                  key="DetailsMovieInfo"
                />
              </Route>
              <Route
                path="/cinema/info"
                element={
                  <HomeProvider>
                    <TheaterInfo />
                  </HomeProvider>
                }
                key="TheaterInfo"
              >
                <Route
                  path="/cinema/info/theater"
                  element={
                    <HomeProvider>
                      <CGV />
                    </HomeProvider>
                  }
                  key="CGV"
                />
                <Route
                  path="/cinema/info/lotte"
                  element={<Lotte />}
                  key="Lotte"
                />
                <Route path="/cinema/info/mega" element={<Mega />} key="Mega" />
              </Route>
              <Route
                path="/mypage"
                element={
                  <HomeProvider>
                    <MyPage />
                  </HomeProvider>
                }
                key="MyPage"
              >
                <Route
                  path="/mypage/order/3"
                  element={
                    <HomeProvider>
                      <MyPageOrder />
                    </HomeProvider>
                  }
                  key="MyPageOrder"
                />
                <Route
                  path="/mypage/user/edit"
                  element={<MyPageUser />}
                  key="MyPageUser"
                />
                <Route
                  path="/mypage/coupon"
                  element={<MyPageCoupon />}
                  key="MyPageCoupon"
                />
                <Route
                  path="/mypage/userbank/create"
                  element={<MyPageUserbank />}
                  key="MyPageUserbank"
                />
              </Route>
              <Route
                path="/cscenter/board"
                element={
                  <HomeProvider>
                    <CSCenter />
                  </HomeProvider>
                }
                key="CSCenter"
              >
                <Route path="/cscenter/board/1" element={<CSCenterNotice />} />
                <Route
                  path="/cscenter/board/guide"
                  element={
                    <HomeProvider>
                      <CSCenterGuide />
                    </HomeProvider>
                  }
                />
                <Route path="/cscenter/board/2" element={<CSCenterFAQ />} />
                <Route
                  path="/cscenter/board/3"
                  element={<CSCenterPurchase />}
                />
                <Route path="/cscenter/board/17" element={<CSCenter1to1 />} />
                <Route
                  path="/cscenter/board/QaBoard/:id"
                  element={<PostViewer />}
                  key="PostViewer"
                />
                <Route
                  path="/cscenter/board/NoticeBoard/:id"
                  element={<PostViewer />}
                  key="PostViewer"
                />
                <Route
                  path="/cscenter/board/PurchaseBoard/:id"
                  element={<PostViewer />}
                  key="PostViewer"
                />
                <Route
                  path="/cscenter/board/OneToOneBoard/:id"
                  element={<PostViewer />}
                  key="PostViewer"
                />
                <Route
                  path="/cscenter/board/create"
                  element={<WriteEditorLite />}
                  key="WriteEditorLite"
                />
                <Route
                  path="/cscenter/board/update/:id"
                  element={<UpdateEditorLite />}
                  key="UpdateEditorLite"
                />
              </Route>
            </Routes>
          </main>
          {/* main 영역 우측에 StickerContainer 위치 */}
          <div className="sticker-right">
            <StickerContainer />
          </div>
        </div>
        <Footer />
        <GlobalPopup
            visible={popupVisible}  // 여기를 $visible로 수정
            setVisible={handleClosePopup}
            imgWidth={popupData.imgWidth}
            imgHeight={popupData.imgHeight}
            mImgWidth={popupData.mImgWidth}
            mImgHeight={popupData.mImgHeight}
            imageUrl={popupData.imageUrl}
          />

        {/* {showPopup && (
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <AppSetupConfig />
          </div>
        )} */}
      </div>
    </AuthProvider>
    </>
  );
}

function Popup() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/mypage/coupon/registercoupon"
          element={<CouponRegister />}
        />
        <Route
          path="/mypage/coupon/productRecommendation"
          element={<ProductRecommendation />}
          key="ProductRecommendation"
        />
        <Route path="/mypage/coupon/shipping" element={<ShippingInfo />} />
        <Route
          path="/mypage/coupon/detailPage/:id"
          element={<ProductDetailPage />}
        />
        <Route
          path="/mypage/coupon/order-complete"
          element={<OrderComplete />}
        />
      </Routes>
    </AuthProvider>
  );
}
function AutoLoginCheck() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  
  useEffect(() => {
    if (isChecked) return;

    const autoLoginToken = localStorage.getItem('autoLoginToken');
    const autoLoginUser = localStorage.getItem('autoLoginUser');

    if (autoLoginToken && autoLoginUser) {
      fetch('/request/validate-token', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: autoLoginToken }),
      })
        .then(response => response.ok ? response.json() : Promise.reject())
        .then(userData => {
          login(userData);
          navigate('/', { replace: true });
        })
        .catch(() => {
          localStorage.removeItem('autoLoginToken');
          localStorage.removeItem('autoLoginUser');
        })
        .finally(() => setIsChecked(true));
    } else {
      setIsChecked(true);
    }
  }, [isChecked]);

  return null;
}

export { App, Popup };

