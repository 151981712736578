import React, { useState, useEffect } from 'react';
import { Outlet, NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';

const NoticeWrapper = styled.div`
  background-color: white;
  width: 90%;
  margin-top: 20px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    flex-direction: column; /* 작은 화면에서 수직 정렬 */
    align-items: flex-start; /* 왼쪽 정렬 */
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  border-bottom: 1px solid #dddddd;

  @media (max-width: 768px) {
    flex-direction: column; /* 작은 화면에서 수직 정렬 */
    align-items: flex-start; /* 왼쪽 정렬 */
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 0;

  @media (max-width: 425px) {
    font-size: 1.5rem; /* 작은 화면에서 폰트 크기 조정 */
  }
`;

const SubTitle = styled.p`
  font-size: 0.875rem;
  color: #555;
  margin-bottom: 5px;
  margin-left: 5px;

  @media (max-width: 768px) {
    margin-left: 0; /* 작은 화면에서 왼쪽 마진 제거 */
    margin-top: 0.5rem; /* Title 아래로 내려가면서 여백 추가 */
  }
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid #cc9933;
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  @media (max-width: 425px) {
    width: 100%;
  }
`;

const CountWrapper = styled.div`
  text-align: left;
  color: #555;
  font-size: 0.8rem;
`;

const Select = styled.select`
  margin-right: 0.5rem;
  padding: 0.5rem;

  @media (max-width: 425px) {
    width: 30%; /* 작은 화면에서 폭 조정 */
  }
`;

const Input = styled.input`
  padding: 0.5rem;
  margin-right: 0.5rem;

  @media (max-width: 425px) {
    width: 50%; /* 작은 화면에서 폭 조정 */
    margin-right: 0.5rem; /* 마진 조정 */
  }
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: #FFC107;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 425px) {
    width: 18%; /* 작은 화면에서 폭 조정 */
    padding: 0.5rem; /* 패딩 조정 */
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #3b5998;

  &:hover {
    text-decoration: underline;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;
`;

const Thead = styled.thead`
  tr {
    border-bottom: 1px solid #ddd;
  }
`;

const Th = styled.th`
  padding: 0.5rem;
  text-align: center;
  font-size: 0.8rem;
  color: #555;
  width: ${({ width }) => width || 'auto'};
`;

const Tbody = styled.tbody`
  tr {
    border-bottom: 1px solid #ddd;
  }
`;

const Td = styled.td`
  padding: 0.5rem;
  font-size: 0.8rem;

  &:nth-child(2) a {
    color: #3b5998;
    text-decoration: none;
  }

  &:nth-child(2) a:hover {
    text-decoration: underline;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 1rem;
  text-align: right;
`;

const DetailWrapper = styled.div`
  padding: 1rem;
`;

const DetailHeader = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const DetailInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export default function CSCenterNotice() {
  const [faqs, setFaqs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchType, setSearchType] = useState('title');
  const [searchTerm, setSearchTerm] = useState('');
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchNotices(searchType, searchTerm, page); // 페이지 정보 전달
};

  useEffect(() => {
    fetchNotices();
  }, []);

  const fetchNotices = (type = '', term = '', page = 1) => {
    let url = `/request/getNoticePage?type=${type}&term=${term}&page=${page}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
          setFaqs(data.posts);
          setTotalPages(data.totalPages); // 총 페이지 수 설정
      })
      .catch((error) => {
          console.error('Error fetching FAQs:', error);
          setFaqs([]);
      });
};

  const handleSearch = () => {
    fetchNotices(searchType, searchTerm);
  };

  return (
    <NoticeWrapper>
      <HeaderWrapper>
        <TitleWrapper>
          <Title>공지사항</Title>
          <SubTitle>예매전 반드시 숙지하시고 예매해주세요.</SubTitle>
        </TitleWrapper>
      </HeaderWrapper>
      <SearchWrapper>
        <Select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
          <option value="title">제목</option>
          <option value="content">내용</option>
        </Select>
        <Input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="검색어를 입력하세요"
        />
        <Button onClick={handleSearch}>검색</Button>
      </SearchWrapper>
      <CountWrapper>
        <div>{faqs.length} 건 조회되었습니다.</div>
      </CountWrapper>
      <Divider />
      <Table>
        <Thead>
          <tr>
            <Th width="10%">번호</Th>
            <Th width="70%">제목</Th>
            <Th width="20%">등록일</Th>
          </tr>
        </Thead>
        <Tbody>
          {faqs.map((faq) => (
            <tr key={faq.id}>
              <Td>{faq.id}</Td>
              <Td>
                <StyledNavLink to={`/cscenter/board/NoticeBoard/${faq.id}`} state={{ faq }}>
                  {faq.title}
                </StyledNavLink>
              </Td>
              <Td>{new Date(faq.created_at).toLocaleDateString()}</Td>
            </tr>
          ))}
          {faqs.length === 0 && (
            <tr>
              <Td colSpan="3">게시글이 없습니다.</Td>
            </tr>
          )}
        </Tbody>
      </Table>
      <Outlet />
      <div style={{marginTop:'30px',paddingRight:'60px'}}>
            <button style={{marginLeft:'10px',marginRight:'10px'}} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>이전</button>
            <span>{currentPage} / {totalPages}</span>
            <button style={{marginLeft:'10px',marginRight:'10px'}} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>다음</button>
        </div>
    </NoticeWrapper>
  );
}

export function Detail() {
  const { id } = useParams();
  const [faq, setFaq] = useState(null);

  useEffect(() => {
    fetch(`/request/getNoticePage/${id}`)
      .then((res) => res.json())
      .then((data) => setFaq(data))
      .catch((error) => console.error('Error fetching FAQ:', error));
  }, [id]);

  if (!faq) {
    return <div>Loading...</div>;
  }

  return (
    <DetailWrapper>
      <DetailHeader>
        {faq.title} ({faq.views})
      </DetailHeader>
      <DetailInfo>
        <span>{new Date(faq.created_at).toLocaleString()}</span>
        <span>작성자: {faq.author} | 조회: {faq.views}</span>
      </DetailInfo>
      <div dangerouslySetInnerHTML={{ __html: faq.content }} />
    </DetailWrapper>
  );
}
