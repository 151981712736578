import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CinemaIntro from "../../components/CinemaIntro";
import PrevNotice from "../../components/PrevNotice";
import FAQNotice from "../../components/FAQNotice";
import AutoHotMoviePreview from "../../components/Auto/AutoHotMoviePreview";
import AutoBoxOfficeRanking from "../../components/Auto/AutoBoxOfficeRanking";
import AutoBestTop8Card from "../../components/Auto/AutoBestTop8Card";
import AutoComingSoon from "../../components/Auto/AutoComingSoonTop4";
import { useAuth } from "../../AuthContext";
import { getSessionFunc } from "../../api/func/userFunc";

const MainWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: hidden;
  position: relative;
`;

const MainContainer1 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 0;
  }
`;

const LeftContainer = styled.span`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;

  /* 768px 이하에서는 맨 아래로 이동 */
  @media (max-width: 768px) {
    order: 3;
    flex-direction: row;
    width: 100%;
  }
`;

const MidContainer = styled.span`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;

  img {
    width: 100%;
    height: auto;
  }

  /* MidContainer는 항상 두 번째 */
  @media (max-width: 1024px) {
    order: 1;
  }
`;

const RightContainer = styled.span`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;

  /* 1024px 이하에서 MidContainer 아래로 이동 */
  @media (max-width: 1024px) {
    order: 2;
  }

  /* 768px 이하에서 MidContainer 바로 아래로 이동 */
  @media (max-width: 768px) {
    order: 2;
    flex-direction: row;
    width: 100%;
  }
`;

const MainContainer2Banner = styled.div`
  width: 100%;
  margin: 10px 0;
  text-align: center;

  img {
    width: 100%;
    height: auto;
  }
`;

const MainContainer3 = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  text-align: center;

  /* 작은 화면에서 flex-direction과 order를 조정 */
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MainContainer4 = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 10px 0;
  text-align: center;

  /* Adjust flex-direction and item order for smaller screens */
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center; /* Center items horizontally */
  }

  span {
    flex: 1;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;

    img {
      max-height: 100%;
      width: auto;
    }
  }
`;

const NoticeBanner = styled.span`
  flex: 40%;
  margin-right: 5px;
  border: 1px solid #dddddd;

  /* Order adjustment for smaller screens */
  @media (max-width: 1024px) {
    order: 1;
    margin-right: 0; /* Remove right margin for vertical alignment */
    margin-bottom: 5px; /* Add bottom margin for spacing between items */
    width: 100%;
  }
`;

const GuideBanner = styled.span`
  flex: 30%;
  margin-right: 5px;
  border: 1px solid #dddddd;

  /* Order adjustment for smaller screens */
  @media (max-width: 1024px) {
    order: 2;
    margin-right: 0;
    margin-bottom: 5px;
    width: 100%;
  }
`;

const PurchaseBanner = styled.span`
  flex: 30%;
  border: 1px solid #dddddd;

  /* Order adjustment for smaller screens */
  @media (max-width: 1024px) {
    order: 3;
    width: 100%;
  }
`;

const ComingSoonWrapper = styled.div`
  width: 40%;
  margin-right: 10px; /* Add some space between the two components */

  /* 작은 화면에서 HotMoviePreviewWrapper 아래로 이동 */
  @media (max-width: 768px) {
    order: 2;
    width: 100%; /* Make it take full width in column layout */
    margin-right: 0; /* Remove margin for vertical alignment */
    margin-top: 10px; /* Add top margin for spacing between items */
  }
`;

const HotMoviePreviewWrapper = styled.div`
  width: 60%;
  margin-left: 10px; /* Add some space between the two components */

  /* 작은 화면에서 첫 번째로 이동 */
  @media (max-width: 768px) {
    order: 1;
    width: 100%; /* Make it take full width in column layout */
    margin-left: 0; /* Remove margin for vertical alignment */
  }
`;
const SubBannerWrapper  = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
export default function Home() {
  const { isLoggedIn, setIsLoggedIn, setUser } = useAuth();
  const [bannerPaths, setBannerPaths] = useState({
    mainBanner: "",
    subBanner: "",
    csCenterBanner: "",
    guideBanner: "",
    purchaseBanner: "",
  });

  useEffect(() => {
    fetch("/control/getBannerPath")
      .then((res) => res.json())
      .then((data) => {
        setBannerPaths({
          mainBanner: data.main_banner || "",
          subBanner: data.sub_banner || "",
          csCenterBanner: data.customer_center || "",
          guideBanner: data.ticketing_guide || "",
          purchaseBanner: data.faq || "",
        });
      })
      .catch((error) => console.error("메인베너요청 실패", error));
  }, []);

  useEffect(() => {
    // console.log("HP");
    const storedUser = JSON.parse(sessionStorage.getItem("user"));

    // id로 session 데이터 가져옴
    const getSession = async (phone_number) => {
      const returnSession = await getSessionFunc(phone_number);
      return returnSession;
    };

    //로그인 상태 판별
    const isLoginFunc = async (storedUser) => {
      // console.log(`=== start is login 3 ===`);
      // 세션 스토리지의 id를 가져온다.
      if (
        storedUser !== null &&
        (storedUser.phone_number !== null ||
          storedUser.phone_number !== undefined)
      ) {
        try {
          // console.log("session 값이 있음");
          // console.log(`storedUser.phone_number : ${storedUser.phone_number}`);
          const returnSession = await getSession(storedUser.phone_number);
          // console.log(returnSession);
          // console.log(JSON.stringify(returnSession));
          // console.log(`code : ${returnSession.code} `);
          if (returnSession.code == 200 && returnSession.result !== 0) {
            // 서버 세션이 남아있을 경우
            // console.log("서버 session true");
            setIsLoggedIn(true);
            setUser(storedUser);
          } else {
            // 서버 Redis에 세션이 없을 경우
            // console.log("서버 session false");
            setIsLoggedIn(false);
            setUser(null);
            sessionStorage.removeItem("user");
          }
        } catch (e) {
          console.log("로그인 판별 에러 : e");
        }
      } else {
        // 세션 스토리지 아이디가 없을 경우
        // console.log("session false");
        setIsLoggedIn(false);
        setUser(null);
      }
      // console.log(`=== end is login ===`);
    };
    isLoginFunc(storedUser);
  }, [isLoggedIn]);
  return (
    <MainWrapper>
      <MainContainer1>
        <LeftContainer className="left-main-container1">
          <PrevNotice />
          <FAQNotice />
        </LeftContainer>
        <MidContainer className="mid-main-container1">
          <AutoBestTop8Card />
          <SubBannerWrapper>
            <img src={bannerPaths.subBanner} alt="sub_banner" />
          </SubBannerWrapper>
        </MidContainer>
        <RightContainer className="right-main-container1">
          <CinemaIntro />
          <AutoBoxOfficeRanking />
        </RightContainer>
      </MainContainer1>
      <MainContainer2Banner>
        <img src={bannerPaths.mainBanner} alt="main-banner" />
      </MainContainer2Banner>
      <MainContainer3>
        <ComingSoonWrapper>
          <AutoComingSoon />
        </ComingSoonWrapper>
        <HotMoviePreviewWrapper>
          <AutoHotMoviePreview />
        </HotMoviePreviewWrapper>
      </MainContainer3>
      <MainContainer4>
        <NoticeBanner>
          <Link to={"/cscenter/board/1"}>
            <img src={bannerPaths.csCenterBanner} alt="notice-banner" />
          </Link>
        </NoticeBanner>
        <GuideBanner>
          <Link to={"/cscenter/board/guide"}>
            <img src={bannerPaths.guideBanner} alt="guide-banner" />
          </Link>
        </GuideBanner>
        <PurchaseBanner>
          <Link to={"/cscenter/board/3"}>
            <img src={bannerPaths.purchaseBanner} alt="purchase-banner" />
          </Link>
        </PurchaseBanner>
      </MainContainer4>
   
    </MainWrapper>
  );
}
